.Dropdown-common-container {
  display: flex;
  flex-direction: column;
  height: 41px;
  margin-bottom: 12px;
  position: relative;
  width: 324px;
}

.Pet-parent-dropdown-container{
  width: 288px !important;
}

.Dropdown-common-selected-item-placeholder {
  color: var(--hinted-text);
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
}

.Dropdown-common-selected-item-text {
  color: #3d484d;
}

.Dropdown-common-search-input {
  align-items: center;
  background-color: var(--ivory-shade-3);
  border-style: none;
  color: #3d484d;
  display: flex;
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  height: 20px;
  justify-content: center;
  outline-color: #2fb3b4;
  pointer-events: all;
  position: relative;
  text-indent: 4px;
  width: 95%;
  z-index: 3;
}

.Dropdown-common-search-input::placeholder {
  color: #d9dee1;
  font-family: 'Inter-Medium';
  font-size: 16px;
  opacity: 1;
}

.Dropdown-common-list-item-container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 5px 10px 2px 9px;
  min-height: 30px;
  outline-color: #2fb3b4;
}

.Dropdown-common-list-item-container[data-disabled=true] > .Dropdown-common-list-item {
  color: var(--deep-blue-shade-3) !important;
}

.Dropdown-common-list-item {
  align-items: center;
  background-color: var(--ivory-shade-3);
  border-color: var(--ivory-shade-3);
  border-radius: 4px;
  color: #3d484d;
  cursor: pointer;
  display: flex;
  font-family: 'Inter-Light';
  font-size: 13.33px;
  height: 36px;
  justify-content: flex-start;
  outline-color: #2fb3b4;
  padding: 0px 5px;
  text-align: left;
  width: 100%;
}

.Dropdown-common-list-item:hover {
  background-color: rgba(184, 237, 232, 0.5);
  border-color: rgba(184, 237, 232, 0.5);
  outline-color: rgba(184, 237, 232, 0.5);
}

.Dropdown-common-list-item-selected {
  background-color: #b8ede8;
  border-color: #b8ede8;
}

.Dropdown-common-list-overlay-container {
  align-items: center;
  background-color: transparent;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  outline-color: #2fb3b4;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

.Dropdown-common-arrow-container {
  cursor: pointer;
  margin-right: 16px;
  outline-color: #2fb3b4;
}

.Dropdown-common-arrow-closed {
  height: 10px;
  transition: all 0.3s ease;
  width: 16px;
}

.Dropdown-common-arrow-open {
  transform: rotate(180deg);
}

.Dropdown-common-header {
  align-content: center;
  align-items: center;
  border-color: var(--deep-blue-shade-2);
  border-style: none none solid none;
  border-width: 1px;
  color: #6d7579;
  cursor: pointer;
  display: flex;
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  height: 41px;
  justify-content: space-between;
  outline-color: #2fb3b4;
  width: 100%;
}

.Dropdown-common-header-error-message {
  color: #cc0000;
  font-family: 'Inter-Medium';
  font-size: 11px;
  padding: 2px 1px 0 10px;
}

.Dropdown-common-selected-item {
  align-items: center;
  display: flex;
  font-family: 'IvyEpic-Regular';
  font-size: 17px;
  height: 100%;
  justify-content: space-between;
  margin-left: 15px;
  pointer-events: none;
  text-indent: 4px;
  width: 100%;
}

.Pet-parent-dropdown-selected-item{
  text-indent: 8px !important;
  margin-left: 0px !important;
}