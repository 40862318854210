.Pet-parent-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 1;
}

.Pet-parent-title {
  align-self: flex-start;
  color: var(--deep-blue-main);
  display: flex;
  font-family: 'IvyPresto-Regular';
  font-size: 36px;
  letter-spacing: -0.4px;
  margin-left: 0 !important;
  text-align: center;
}

.Pet-parent-title-partner {
  display: block;
  margin-top: 7px;
  margin-left: 24px;
}

.Pet-parent-title-no-partner {
  margin: 0 0 24px;
}

.Pet-parent-input-container {
  display: flex;
  flex-direction: column;
  height: auto !important;
  min-height: 70px;
  position: relative;
  width: 288px;
}

.Pet-parent-inputs-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: auto !important;
  min-height: 70px;
  min-width: 320px;
  position: relative;
}

.Pet-parent-state-zip-inputs {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 16px;
}

.Pet-parent-state-input {
  display: flex;
  flex-direction: column;
  min-height: 72px;
  width: 168px;
}

.Pet-parent-zip-input-container {
  display: flex;
  flex-direction: column;
  min-height: 72px;
  width: 108px;
}

.Pet-parent-zip-input {
  display: flex;
  flex-direction: column;
  min-height: 72px;
  width: 134px;
}

.Pet-parent-inputs-row {
  display: flex;
  gap: 32px;
  margin-bottom: 8px;
  width: 100%;
}

.Pet-parent-input-size {
  flex: none !important;
  min-width: 320px;
}

.Pet-parent-input-label {
  color: var(--deep-blue-main);
  display: flex;
  flex-direction: column;
  font-family: 'IvyEpic-Bold';
  font-size: 14px;
  line-height: normal;
  margin-bottom: 4px;
}

.Pet-parent-input-label>input:read-only {
  opacity: 0.7;
}

.Pet-parent-input-label:has(input:read-only) {
  opacity: 0.7;
}

/* Safari 9.0 and up support */
@supports (-webkit-hyphens:none) {
  input[type="password"] {
    font-size: 12px;
    line-height: normal;
  }
}

.Second-parent-button-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 40px 0 20px;
  outline-color: #2fb3b4;
}

.Second-parent-plus-icon {
  height: 20px;
  margin-right: 8px;
  width: 20px;
}

.Second-parent-button-title {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Bold';
  font-size: 16px;
  white-space: nowrap;
}

.Second-parent-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Second-parent-brief-description {
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 32px;
  text-align: center;
  width: 544px;
}

.Second-parent-button-span-container {
  display: inline-grid;
  justify-content: center;
}

/* Tablet - Medium */
@media (max-width: 710px) {
  .Pet-parent-container {
    width: 100%;
  }
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Pet-parent-title {
    display: unset;
    margin-top: 0;
  }

  .Pet-parent-title-no-partner {
    position: unset;
    margin-bottom: 24px;
  }

  .Pet-parent-container {
    margin-top: 0;
  }
}

/* Tablet */
@media (max-width: 700px) {
  .Pet-parent-inputs-container {
    flex: 1;
    min-width: 250px;
    width: auto;
  }

  .Pet-parent-state-input {
    width: 40%;
  }

  .Pet-parent-zip-input {
    width: 30%;
  }

  .Pet-parent-title-partner {
    margin-left: 0;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Pet-parent-input-container,
  .Dropdown-common-container {
    width: 100%;
  }

  .Pet-parent-inputs-container {
    width: 100%;
  }

  .Pet-parent-container {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .Pet-parent-title {
    font-size: 24px;
    margin: 26px 0 24px 16px;
    text-align: center;
  }

  .Pet-parent-inputs-row {
    gap: 0;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
  }

  .Pet-parent-state-input {
    flex: 2;
    width: 168px;
  }

  .Pet-parent-zip-input-container {
    min-height: 56px;
    max-height: 56px;
    width: 110px;
  }

  .Pet-parent-zip-input {
    flex: 1;
    width: 134px;
  }

  .Second-parent-button-container {
    margin-bottom: 18px !important;

  }

  .Second-parent-form {
    align-items: center;
    flex-direction: column;
    margin-bottom: 48px;
    width: 100%;
  }

  .Second-parent-brief-description {
    font-size: 14px;
    line-height: 1.429 !important;
    margin: 0 25px 25px 24px !important;
    width: 270px !important;
  }
}