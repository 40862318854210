.Partner-discount-label {
  align-items: center;
  background-color: #ffffffbf;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px 30px 0;
  padding: 12px 16px;
  width: 100%;
}

.Partner-discount-label-container {
  align-items: center;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: space-between;
  width: 100%;
}

.Partner-discount-label-message {
  color: #22435e;
  display: flex;
  font-size: 20px;
}

.Partner-discount-label-message span {
  align-self: center;
  font-family: 'Inter-Semibold';
  margin-left: 12px;
}

.Partner-discount-label-logo {
  margin-bottom: 16px;
  width: 124px;
}

.Partner-discount-label-tag-icon {
  height: 32px;
}

.Partner-discount-label-badge {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  min-width: 73px;
}

.Partner-discount-label-badge span {
  color: #7c52bf;
  font-family: 'IvyEpic-Regular';
  font-size: 18px;
  font-weight: 600;
}

.Partner-discount-label-estimated-div {
  align-items: center;
  display: flex;
  min-height: 30px;
}

.Partner-discount-label-estimated {
  align-items: center;
  display: flex;
  padding-right: 8px;
}

.Partner-discount-label-estimated img {
  height: 20px;
  width: 20px;
}

.Partner-discount-label-estimated span {
  color: #1e2226;
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  padding-left: 4px;
}

/* Tablet */
@media (max-width: 1200px) {
  .Partner-discount-label {
    margin: 24px 32px;
    width: calc(100% - 100px);
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Partner-discount-label {
    justify-content: center;
    margin: 32px 0 0 0;
    padding: 16px;
    width: 0;
  }

  .Partner-discount-label-container {
    flex-direction: column-reverse;
  }

  .Partner-discount-label-logo {
    margin-bottom: 8px;
    width: 72.28px;
  }

  .Partner-discount-label-message {
    margin-top: 9px;
  }

  .Partner-discount-label-tag-icon {
    height: 20px;
  }

  .Partner-discount-label-message span {
    align-self: center;
    font-family: 'IvyEpic-Regular';
    margin-left: 0;
  }

  .Partner-discount-label-estimated-div {
    margin-top: 8px;
    order: -1;
  }

  .Partner-discount-label-message {
    font-size: 16px;
  }
}
