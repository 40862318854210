.Radio-button,
.Radio-button::before,
.Radio-button::after {
  box-sizing: border-box;
}

.Radio-button {
  align-items: center;
  display: flex;
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  line-height: 24px;
  position: relative;
}

.Radio-button input[type="radio"] {
  appearance: none;
  border-radius: 20px;
  cursor: pointer;
  height: 20px;
  left: -5px;
  position: absolute;
  top: -1px;
  width: 20px;
}

.Radio-button-img {
  height: 20px;
  margin-right: 16px;
  width: 20px;
}

/* Mobile */
@media (max-width: 568px) {
  .Radio-button {
    line-height: 20px;
  }
}
