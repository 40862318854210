.Custom-modal-container {
  align-items: center;
  height: 412px;
  justify-content: center;
  padding: 32px 0 40px 0;
  width: 480px !important;
}

.Custom-modal-image-container {
  min-height: 95px;
  min-width: 178px;
}

.Custom-modal-title {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Bold';
  font-size: 22px;
  line-height: 30px;
  margin-top: 16px;
  text-align: center;
}

.Custom-modal-button {
  background-color: #73dad2;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  height: 60px;
  width: 352px;
}

.Custom-modal-button-text {
  color: #22435e;
  font-family: 'Inter-SemiBold';
  font-size: 16px;
}

.Custom-modal-description-text {
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  line-height: 23px;
  margin: 12px 0 33px 0;
  text-align: center;
  white-space: pre-line;
  width: 384px;
}

/* Mobile */
@media(max-width: 568px) {
  .Custom-modal-container {
    width: 100%;
  }

  .Custom-modal-description-text, .Custom-modal-title  {
    width: 100%;
  }
}
