.Sidebar-save-quote-modal-confirmation-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 328px;
  justify-content: flex-start;
  width: 381px;
}

.Sidebar-save-quote-modal-confirmation-inbox {
  height: 60px;
  margin-top: 35px;
  width: 60px;
}

.Sidebar-save-quote-modal-confirmation-header-container {
  margin-top: 18px;
}

.Sidebar-save-quote-modal-confirmation-header {
  color: var(--deep-blue-main);
  font-family: 'Inter-SemiBold';
  font-size: 22px;
  margin: 0;
}

.Sidebar-save-quote-modal-confirmation-desc-container {
  margin-top: 16px;
  text-align: center;
  width: 317px;
}

.Sidebar-save-quote-modal-confirmation-desc {
  color: var(--deep-blue-shade-2);
  font-size: 16px;
}

.Sidebar-save-quote-modal-confirmation-button {
  background-color: var(--deep-blue-main);
  border-radius: 8px;
  color: var(--ivory-main);
  font-family: 'Inter-SemiBold';
  font-size: 16px;
  height: 60px;
  margin-top: 32px;
  width: 150px;
}
