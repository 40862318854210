.Questions-section-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 64px 0 40px 0;
  width: 100%;
  background-color: var(--deep-blue-main);
  padding-bottom: 40px;
}

.Questions-section-innerent-quote {
  margin: 0;
}

.Questions-section-title {
  color: var(--citron-main);
  font-family: 'IvyPresto-Regular';
  font-size: 28px;
  font-weight: normal;
  margin-bottom: 24px;
  margin-top: 40px;
  text-align: center;
}

.Questions-section-desciption {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
}

.Questions-section-call-text,
.Questions-section-questions-text-subtitle {
  color: var(--ivory-main);
  font-size: 14px;
  line-height: 21px;
  font-family: 'IvyEpic-Bold';
  font-weight: 700;
}

.Questions-section-call-text {
  font-family: 'Inter-SemiBold';
  margin: 0;
  text-align: center;
  width: 100%;
}

.Questions-section-call-text > a {
  color: var(--ivory-main);
}

.Questions-section-questions-text-subtitle {
  font-family: 'IvyEpic-Regular';
  font-weight: 400;
  margin-bottom: 0px;
  text-align: center;
  width: 200px;
}

.Questions-section-questions-text {
  color: var(--ivory-main);
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  line-height: 21px;
}

.Questions-section-purchase {
  background-color: transparent !important;
  display: block;
  margin: 0;
}

.Questions-section-login {
  background-color: transparent;
  margin-top: 15.12px;
}

.Questions-section-login .Questions-section-title {
  color: #1e2226;
  font-family: "IvyPresto-Regular";
  font-size: 28px;
  font-weight: 400;
}

.Questions-section-login .Questions-section-call-text {
  color: var(--deep-blue-shade-1);
  font-family: "IvyEpic-Bold";
  font-size: 14px;
  font-weight: 700;
}

.Questions-section-login .Questions-section-questions-text-subtitle {
  color: var(--deep-blue-shade-1);
  font-family: "IvyEpic-Regular";
  font-size: 14px;
  font-weight: 400;
  margin-top: 12px;
}

.Questions-section-purchase .Questions-section-questions-text {
  color: #22435e;
  font-size: 16px;
}

.Questions-section-purchase .Questions-section-questions-text > a {
  color: #22435e;
  font-family: 'Inter-Medium';
}

.Questions-section-purchase {
  color: #1e2226;
  font-size: 14px;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Questions-section-purchase {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 40px 0 48px 0;
  }

  .Questions-section-purchase .Questions-section-questions-text {
    text-align: left;
  }

  .Questions-section-purchase .Questions-section-header {
    display: flex;
    flex-direction: column;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Questions-section-help-desk-icon {
    max-width: 200px;
    width: 100%;
  }

  .Questions-section-title {
    margin: 18px 0;
  }

  .Questions-section-desciption {
    max-width: 262px;
    width: 100%;
  }

  .Questions-section-questions-text {
    margin: 12px 0;
  }

  .Questions-section-purchase .Questions-section-header {
    display: flex;
    flex-direction: column-reverse;
  }

  .Questions-section-purchase {
    align-items: flex-start;
    flex-direction: column;
  }

  .Questions-section-purchase {
    margin: 0 0 16px 0;
  }
}
