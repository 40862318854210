.Partner-discount-callout-container {
  align-content: center;
  align-items: center;
  background-color: rgba(184, 237, 232, 0.25);
  border-radius: 8px;
  border: 1px solid #b8ede8;
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 100%;
}

.Partner-discount-callout-content {
  color: #3d484d;
  font-size: 14px;
}

.Partner-discount-callout-membership {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.Partner-discount-callout-membership img {
  height: 28px;
  margin: 0 8px 0 12px;
  padding: 0;
  width: 44px;
}

.Partner-discount-callout-content-container {
  display: flex;
  flex-direction: row;
}

.Partner-discount-callout-title {
  color: #22435e;
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  line-height: 18px;
}

.Partner-discount-callout-content {
  font-family: 'IvyEpic-Regular';
  margin-left: 18px;
}

/* Mobile */
@media (max-width: 568px) {
  .Partner-discount-callout-container {
    margin-top: 17px;
    padding: 11px 0 11px 12px;
  }

  .Partner-discount-callout-membership {
    align-items: end;
    justify-content: center;
  }

  .Partner-discount-callout-content-container {
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    margin-left: 12px;
  }

  .Partner-discount-callout-membership img {
    margin: 0;
  }

  .Partner-discount-callout-title {
    align-self: start;
  }

  .Partner-discount-callout-content {
    align-self: end;
    margin: 5px 0 0 0;
  }
}