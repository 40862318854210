.Header-underwriting {
  color: var(--ivory-40);
  display: flex;
  gap: 4px;
  padding: 6px 32px;
  position: absolute;
  right: 0;
  text-align: right;
  z-index: 1;
}

.Header-underwriting>span,
.Header-underwriting a {
  color: var(--ivory-40);
  font: 14px 'IvyEpic-Regular';
  font-size: 14px;
}

/* Tablet */
@media (max-width: 1200px) {
  .Header-underwriting {
    padding: 24px 16px;
  }

  .Header-underwriting-call {
    display: none;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Header-container>span {
    font-size: 10px;
    width: 80%;
  }

  .Header-underwriting-call {
    display: none;
  }
}