.Tooltip-discount-main-container {
  position: relative;
}

.Tooltip-discount-overlayed-container {
  align-items: center;
  background-color: #b8ede8;
  border-radius: 8px;
  display: flex;
  height: 76px;
  justify-content: center;
  left: -28px;
  position: absolute;
  right: 0;
  top: 30px;
  width: 234px;
}

.Tooltip-discount-overlayed-container > span {
  color: #1e2226;
  font-family: 'Inter-Regular';
  font-size: 14px;
  line-height: 1.429;
  margin: 0 16px;
  text-decoration: none !important;
}

.Tooltip-discount-arrow-down {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 10px solid #b8ede8;
  top: -10px;
  height: 0;
  left: 38px;
  position: absolute;
  width: 0;
}

.Tooltip-discount-icon-container {
  background: transparent;
  cursor: pointer;
  height: 20px;
  padding: 0;
}

.Tooltip-discount-icon-container > img {
  height: 20px;
  width: 20px;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Tooltip-discount-overlayed-container {
    z-index: 4;
  }
}
