.Price-sticky-bar-container {
  bottom: 0;
  display: block;
  position: fixed;
  width: 100%;
  z-index: 10;
}

.Price-sticky-bar-price-inner-container {
  align-items: center;
  background-color: var(--citron-main);
  display: flex;
  gap: 41px;
  justify-content: center;
  padding: 24px;
}

.Price-sticky-bar-price-container {
  align-items: center;
  display: inherit;
  flex-direction: row;
  gap: 2px;
  justify-content: center;
}

.Price-sticky-bar-price {
  font-family: "Inter-SemiBold";
  font-weight: 600;
  font-size: 24px;
  color: var(--deep-blue-main);
}

.Price-sticky-bar-price-cycle {
  font-family: "Inter-Medium";
  font-weight: 500;
  font-size: 16px;
  color: var(--deep-blue-main);
}

.Price-sticky-bar-price-details {
  color: var(--deep-blue-main);
  font-family: "Inter-regular";
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
  text-align: center;
}

.Price-sticky-bar-continue-button {
  align-items: center;
  background-color: var(--deep-blue-main);
  border-radius: 30px;
  color: var(--ivory-shade-3);
  cursor: pointer;
  display: flex;
  font-family: 'Inter-SemiBold';
  font-size: 16px;
  height: 60px;
  justify-content: center;
  width: 266px;
}

.Price-sticky-bar-button-icon {
  height: 16px;
  margin-left: 16px;
  width: 10px;
}


/* Mobile */
@media (max-width: 568px) {
  .Price-sticky-bar-price {
    font-size: 18px;
  }

  .Price-sticky-bar-price-cycle {
    font-size: 14px;
    font-weight: 400;
  }

  .Price-sticky-bar-price-details {
    font-size: 11px;
    margin-top: 0;
  }

  .Price-sticky-bar-price-container {
    flex-direction: column;
  }

  .Price-sticky-bar-price-inner-container {
    gap: 24px;
  }

  .Price-sticky-bar-continue-button {
    font-size: 14px;
    height: 41px;
    width: 178px;
  }

  .Price-sticky-bar-button-icon {
    margin-left: 8px;
  }
}
