.Important-dates-main-card {
  background-color: var(--ivory-shade-3);
  border-radius: 18px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  width: 95%;
}

.Important-dates-title-header {
  color: var(--deep-blue-main);
  font-family: "IvyPresto-Regular";
  font-size: 36px;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 31px;
  margin: 0 0 24px 0;
}

.Important-dates-cards-container {
  display: inherit;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 16px;
}

.Important-dates-invdividual-card {
  border-radius: 5px;
  border: 1px solid var(--deep-blue-main);
  display: inherit;
  flex-direction: row;
  max-width: 312px;
  padding: 12px;
  width: 44%;
}

.Important-dates-invdividual-card-icon {
  height: 20px;
  width: 19px;
}

.Important-dates-invdividual-card-col {
  display: inherit;
  flex-direction: column;
  height: 100%;
  margin-left: 9px;
}

.Important-dates-invdividual-card-title {
  color: var(--deep-blue-shade-1);
  font-family: "IvyEpic-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.Important-dates-invdividual-card-description {
  color: var(--deep-blue-main);
  font-family: "IvyEpic-Regular";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.Important-dates-description {
  color: var(--deep-blue-shade-2);
  font-family: "IvyEpic-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-top: 10px;
}

/* Mobile */
@media (max-width: 568px) {
  .Important-dates-main-card {
    border-radius: 0;
    box-shadow: none;
    margin-top: 16px;
  }

  .Important-dates-title-header {
    align-self: flex-start;
    font-size: 24px;
    margin-bottom: 24px;
    text-align: center;
  }

  .Important-dates-invdividual-card {
    align-items: center;
    flex-direction: column;
    padding: 8px;
    text-align: center;
    width: 43%;
  }

  .Important-dates-invdividual-card-col {
    margin: 4px 0 0 0;
  }

  .Important-dates-invdividual-card-title {
    align-self: center;
    font-size: 14px;
    line-height: 21px;
    width: 80%;
  }

  .Important-dates-invdividual-card-description {
    font-size: 14px;
    line-height: 21px;
    margin-top: 4px;
  }

  .Important-dates-description {
    text-align: center;
  }
}
