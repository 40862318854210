.Quote-content {
  align-items: center;
  background-image: url(../../assets/bg.png);
  background-size: cover;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 4;
}
