.Modal-login-user-subscription-bubbles-image {
  height: 67.31px;
  margin-top: 32.42px;
  width: 127.12px;
}

.Modal-login-user-subscription-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 391px;
  width: 480px !important;
}

.Modal-login-img {
  height: 67px;
  margin-top: 32.65px;
  width: 127px;
}

.Modal-login-user-subscription-primary-button {
  background-color: var(--deep-blue-main);
  border-radius: 8px;
  border: none;
  cursor: pointer;
  height: 57px;
  width: 112px;
}

.No-modal-login-user-subscription-primary-button {
  align-self: flex-end;
  height: 48px;
  margin-left: 20.5px;
  width: 107px;
}

.Modal-login-user-subscription-primary-button-text {
  color: var(--ivory-main);
  font-family: 'Inter-SemiBold';
  font-size: 16px;
}

.Modal-login-user-subscription-title {
  color: var(--deep-blue-main);
  font-family: 'Inter-SemiBold';
  font-size: 22px;
  line-height: 30px;
  margin: 15.04px 0 0 0;
}

.Modal-login-user-subscription-title-get-quote {
  color: #22435e;
  font-family: 'Inter-SemiBold';
  font-size: 18px;
  line-height: 30px;
  margin-top: 23.33px;
}

.Modal-login-user-subscription-description {
  color: var(--deep-blue-shade-2);
  font-family: 'Inter-Regular';
  font-size: 16px;
  height: 46px;
  line-height: 23px;
  margin-top: 16px;
  text-align: center;
  width: 416px;
}


.No-modal-login-user-subscription-description {
  margin-top: 8px;
  text-align: left;
  width: auto;
}

.Login-input-email-container {
  height: 60px;
  margin-top: 24px;
  width: 352px;
}

.Login-input-container-class {
  margin-top: 4px;
  height: 20px;
}

.Login-input-text {
  color: #3d484d;
  font-family: 'Inter-Medium';
  font-size: 13px;
  padding-left: 8px;
}

.Login-input-email {
  width: auto !important;
}

.Login-input-password {
  height: 20px;
}

.Login-input-password-no-modal {
  width: 220px !important;
}

.Login-input-password-general-container {
  display: flex;
  height: 56px;
  justify-content: space-between;
  margin-top: 16px;
  width: 400px;
}

.Login-input-password-general-container-no-modal {
  align-items: flex-start;
  display: flex;
  height: 56px;
  margin-top: 16px;
  width: 352px;
}

.Login-input-password-container {
  display: flex;
  flex-direction: column;
  width: auto;
}

.Login-input-password-container-no-modal {
  width: 220px;
}

.Modal-login-user-button-container {
  padding-top: 40px;
  width: auto;
}

.No-modal-login-user-button-container {
  margin: 0px !important;
  padding-bottom: 40px;
  padding-top: 0px;
}

#inpPasswordUser, #inpEmailUser {
  background: none;
}

.Modal-login-user-input-headers {
  justify-content: space-between;
  max-width: 248px;
}

.Modal-login-user-input-left {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 24px;
  padding-top: 18px;
}

.Modal-login-user-input-headers > span {
  color: #3d484d;
}

.Modal-login-user-input {
  border-color: #d9dee1;
  border-radius: 0.5px;
  border-style: none none solid none;
  border-width: 1px;
  color: #3d484d;
  font-family: 'Inter-Medium';
  font-size: 20px;
  max-width: 248px;
  outline-color: #2fb3b4;
}

.Modal-login-user-input:focus {
  border-image: linear-gradient(to right, #1cd4ce 0%, #1ecacb 13%, #2d86ba 100%) 1 stretch;
  border-radius: 1px;
  border-width: 2px;
}

.Modal-login-user-footer-container {
  color: var(--deep-blue-shade-2);
  font-family: 'Inter-Regular';
  font-size: 16px;
  margin: 49px 0 32px;
}

.Modal-login-user-footer-container > div {
  font-family: 'Inter-SemiBold';
}

.Modal-login-click-here {
  color: var(--deep-blue-main);
  font-family: 'Inter-Regular';
  font-size: 16px;
  text-decoration: underline;
}

/* Mobile */
@media(max-width: 568px) {
  /* Temporary change in order to avoid design issues */
  /* (there is no sketch for this and will be hidden in the future) */
  .Login-input-password-general-container-no-modal {
    flex-wrap: wrap;
    height: auto;
    width: 100%;
  }

  .Login-input-email-container {
    width: 95%;
  }

  .Modal-login-user-subscription-container {
    width: 90%;
  }

  .Modal-login-user-subscription-bubbles-image {
    height: 57.41px;
    width: 108.25px;
  }

  .Modal-login-user-subscription-title {
    margin-top: 16px;
  }

  .Modal-login-user-subscription-description  {
    font-size: 13px;
    height: auto;
    line-height: 16px;
    margin: 16px 0;
    width: 73%;
  }

  .Modal-login-user-button-container {
    padding-top: 0;
  }

  .Login-input-password-general-container {
    flex-wrap: wrap;
    height: auto;
    width: 100%;
  }
  
  .Modal-login-user-subscription-primary-button {
    margin-top: 24px;
    width: 100%;
  }

  .Modal-login-user-footer-container {
    font-size: 13px;
    margin: 16px 0 32px;
  }
}
