.Powerup-cards-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  width: 100%;
}

.Powerup-cards-container > section {
  width: 72%;
}

.Powerup-title-info {
  align-items: flex-start;
  display: flex;
  margin: 16px 0 24px;
  flex-direction: column;
}

.Powerup-header-title {
  color: var(--deep-blue-main);
  font-family: 'IvyPresto-Regular';
  font-size: 36px;
  font-weight: normal;
  letter-spacing: -0.4px;
  line-height: 46px;
  margin: 0 0 8px 0;
  max-width: 500px;
  overflow: hidden;
}

.Powerup-title-description {
  color: var(--deep-blue-shade-2);
  display: flex;
  font-family: 'IvyEpic-Regular';
  font-size: 12px;
  line-height: 18px;
  margin: 0 62px 0 0;
  text-align: left;
}

.Powerup-wellness-price-checkbox, .Powerup-wellness-title {
  color: var(--deep-blue-shade-2);
  cursor: pointer;
  font-family: 'IvyEpic-Regular';
  font-size: 12px;
  margin-top: 2px;
}

.Powerup-wellness-title {
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic-Bold';
  font-size: 12px;
}

.Powerup-wellness-checkbox-container > p,
.Powerup-wellness-checkbox-container-first > p {
  margin: 0;
}

.Powerup-wellness-checkbox-container-first {
  margin-top: 18.98px;
}

.Powerup-wellness-checkbox-row-container {
  height: 20px;
}

.Powerup-wellness-checkbox-row-container:first-of-type {
  margin-bottom: 15px;
}

.Powerup-wellness-link-content-radio,
.Powerup-wellness-link-content {
  display: none;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Powerup-cards-container {
    height: fit-content;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Powerup-cards-container {
    width: 100%;
  }

  .Powerup-cards-container > section {
    width: 95%;
  }

  .Powerup-title-header-container {
    flex-direction: column;
    margin-bottom: 24px;
  }

  .Powerup-header-title {
    font-size: 24px;
    margin: 14px 0 0 0;
    text-align: center;
  }

  .Powerup-title-description {
    margin: 0 16px 0 0;
  }

  .Powerup-wellness-checkbox-row-container {
    height: 60px;
  }

  .Powerup-wellness-checkbox-container-first {
    margin-top: 16px;
  }

  .Powerup-wellness-checkbox-row-container:first-of-type {
    margin-bottom: 16px;
  }

  .Powerup-wellness-price-checkbox {
    font-family: 'IvyEpic-Regular';
    font-size: 12px;
  }

  .Powerup-wellness-link-container {
    display: flex;
  }

  .Powerup-wellness-link-content-radio {
    display: flex;
    margin-top: 8px;
    padding: 0;
  }

  .Powerup-wellness-link-content {
    display: flex;
    left: 60px;
    margin-top: 8px;
    padding: 0;
    position: absolute;
  }
}
