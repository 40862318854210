.Modal-customer-welcome-container {
    align-items: center;
    height: 390px;
    justify-content: center;
    padding-bottom: 40px;
    width: 480px !important;
}

.Modal-customer-welcome-container p {
  color: #3d484d;
  text-align: center;
  max-width: 416px;
}

.Modal-customer-welcome-got-it {
  background-color: var(--deep-blue-main);
  border-radius: 8px;
  border: none;
  color: var(--ivory-main);
  cursor: pointer;
  height: 60px;
  margin-top: 7px;
  outline-color: #22435eaa !important;
  outline: auto;
  width: 352px;
}

.Modal-customer-got-it-text {
  color: #fff;
  font-family: 'Inter-SemiBold';
  font-size: 16px;
}

/* Mobile */
@media (max-width: 568px) {
  .Modal-customer-welcome-container {
    width: 90% !important;
  }

  .Modal-customer-welcome-container > p {
    font-size: 13px;
  }

  .Modal-customer-welcome-got-it {
    width: 100%;
  }
}
