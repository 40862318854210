.Checkbox-container {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 450px;
}

.Checkbox-keep-space {
  height: 36px;
}

.Checkbox-container input {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d9dee1;
  cursor: pointer;
  height: 20px;
  margin-right: 8px;
  min-width: 20px;
  position: relative;
  top: 4px;
  width: 20px;
}

.Checkbox-container-rounded input {
  border-radius: 100%;
}

.Checkbox-container input:checked {
    background: no-repeat center url("../../assets/check.svg");
    border: transparent !important;
    height: 20px;
    width: 20px;
    min-width: 20px;
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.3s;
}

.Checkbox-container input:focus {
  outline-color: #2fb3b4;
}

.Checkbox-label {
  align-items: center;
  color: #3d484d;
  cursor: pointer;
  display: flex;
  font-family: 'Inter-Semibold';
  font-size: 13px;
  height: fit-content;
  padding-bottom: 0px !important;
  pointer-events: none;
}

.Checkbox-label span {
  align-self: center;
}

.Checkbox-input {
  top: 0px !important;
}

.Checkbox-content {
  align-self: center;
  display: flex;
  height: auto;
  width: 100%;
}

.Checkbox-error-label {
  bottom: 3px;
  color: #c00000;
  font-family: 'Inter-Medium';
  font-size: 11px;
  pointer-events: none;
  position: relative;
}

.Checkbox-error-label:not(:empty) {
  padding-left: 33px;
}

/* Mobile */
@media (max-width: 568px) {
  .Checkbox-container input {
    background-color: #fff;
  }

  .Checkbox-container {
    margin-bottom: 8px;
    width: 100%;
  }

  .Checkbox-keep-space {
    height: auto;
  }
}
