.Generic-partner-discount-label-container {
  align-items: center;
  background-color: #f8f8f8;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  height: auto;
  justify-content: center;
  padding: 12px 16px 12px 24px;
  width: 100%;
}

.Generic-partner-discount-label {
  display: flex;
  flex: 1;
  margin: 20px 0;
}

.Generic-partner-discount-label-message {
  color: #22435e;
  display: flex;
  font-size: 20px;
}

.Generic-partner-discount-image {
  height: 36px;
  padding-right: 20px;
}
