.Landing-footer-container {
  background-color: #002028;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 161px 40px 160px;
}

.Landing-footer-app-logo {
  border-radius: 21.49px;
  height: 80px;
  width: 80px;
}

.Landing-footer-app-logo-container {
  display: flex;
  flex-direction: column;
}

.Landing-footer-app-logo-text>p {
  margin: 0;
}

.Landing-footer-app-logo-text>p:last-child {
  margin-bottom: 24px;
}

.Landing-footer-app-logo-text {
  color: #d9dee1;
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  text-align: justify;
}

.Landing-footer-good-dog {
  margin: 36px;
}

.Landing-footer-good-dog>p>p {
  margin: 7px 0 !important;
}

.Landing-footer-good-dog>p>p>a {
  color: #d9dee1;
  font-size: 14px;
}

.Landing-footer-container h4 {
  color: var(--ivory-main);
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Landing-footer-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Landing-footer-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .Landing-footer-app-logo-container {
    align-items: center;
    flex-direction: column;
  }

  .Landing-footer-app-logo-text {
    font-size: 14px;
    margin: 32px auto;
    width: 91%;
  }
}