.Purchase-error-container {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.10);
  height: 367px;
  margin-top: -81px;
}

/* Mobile */
@media (max-width: 568px) {
  .Purchase-error-container {
    display: flex;
    height: 370px;
    margin-top: 25px;
    padding-bottom: 40px;
    width: 90%;
  }
}
