.Your-info-agreements-container {
  display: flex;
  flex-direction: column;
  width: 407px;
}

.Your-info-agreements-container p {
  margin: 0;
}

.Your-info-agreements-container label {
  align-items: center;
  color: var(--deep-blue-shade-2);
  cursor: pointer;
  display: flex;
  font-family: 'IvyEpic-Regular';
  font-size: 12px;
  padding-bottom: 8px;
}

.Terms-condition-checkbox > .Checkbox-content {
  align-self: center;
  display: block;
}

/* Mobile */
@media (max-width: 568px) {
  .Your-info-agreements-container label {
    width: 100%;
  }

  .Your-info-agreements-container {
    align-items: flex-start;
    justify-content: center;
    width: 80%;
  }

  .Terms-condition-checkbox > .Checkbox-content {
    width: 70%;
  }

  .Terms-and-condition-check > .Checkbox-content {
    clear: none;
    display: unset;
    overflow: hidden;
    white-space: pre-line;
    width: 73%;
  }
}