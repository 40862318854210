.Landing-right-container {
  height: 100%;
  position: relative;
}

.Landing-top-right-circle {
  height: 566px;
  left: 600px;
  opacity: 0.5;
  position: absolute;
  top: -340px;
  width: 566px;
}

.Landing-top-right-circle-2 {
  height: 317px;
  position: absolute;
  right: 43px;
  top: 68px;
  width: 317px;
}

.Landing-top-right-circle-3 {
  position: absolute;
  top: 55px;
  right: -257px;
  height: 566px;
  width: 566px;
  z-index: -1;
}

.Landing-left-white-circle-center {
  height: 475px;
  width: 475px;
  position: absolute;
  top: -195px;
  right: -275px;
}

.Landing-right-card-container {
  display: flex;
  position: relative;
  justify-content: center;
}

.Landing-right-card {
  align-items: center;
  background-color: var(--ivory-shade-2);
  border-radius: 12px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  margin: -180px 0 10px 0;
  z-index: 3;
}

.Landing-right-card-partner {
  margin-top: -90px;
}

.Landing-bottom-white-circle-container {
  display: none;
}

.Landing-bottom-white-circle {
  height: 475px;
  opacity: 0.7;
  position: unset;
  width: 475px;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Landing-right-card-partner {
    margin: 0 0 213px !important;
  }
}

/* Tablet - Medium */
@media (max-width: 768px) {
  .Landing-right-card {
    margin-top: -47px;
  }

  .Landing-right-card-container {
    margin: 0;
  }

  .Landing-right-container {
    position: relative;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Landing-right-container {
    background-color: var(--ivory-shade-2);
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    width: 100%;
  }

  .Landing-right-card,
  .Landing-right-card-container {
    width: 100%;
  }

  .Landing-right-card {
    box-shadow: none;
    border-radius: 0;
    margin-top: 0px;
  }

  .Landing-right-card-partner {
    margin: 0 0 100px !important;
  }
}
