.Modal-add-pet-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 22px 24px 0 24px;
  width: 368px;
}

.Modal-add-pet-content-landing {
  padding: 32px 24px 0 24px;
}

.Modal-add-pet-log {
  opacity: 0;
  position: absolute;
}

.Modal-add-pet-title {  
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Bold';
  font-size: 22px;
  line-height: 32px;
  margin: 0;
}

.Modal-add-pet-title-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Modal-add-pet-title-separator {
  background-color: var(--citron-shade-1);
  border: none;
  height: 2px;
  margin: 6px 0;
  width: 128px;
}

.Modal-add-pet-title-description {
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  line-height: 21px;
}

.Modal-add-pet-yellow-bar {
  background-color: var(--citron-main);
  height: 2px;
  margin-top: 14px;
  width: 128px;
}

.Modal-add-pet-species-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Modal-add-pet-species-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
}

.Modal-add-pet-species-title {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Bold';
  font-size: 14px;
}

.Modal-add-pet-specie-icon {
  height: 38px;
}

.Modal-add-pet-specie-tipe-title {
  color: #6d7579;
  font-family: 'Inter-Medium';
}

.Modal-add-pet-specie-item-container {
  align-items: center;
  background-color: #ffffffbf;
  border: 1pt solid var(--gray-border);
  border-radius: 36px;
  color: var(--deep-blue-shade-3);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: 'IvyEpic-Regular';
  font-size: 18px;
  gap: 10px;
  justify-content: center;
  min-width: 136px;
  padding: 8px 16px;
  transition: all ease 0.3s;
}

.Modal-add-pet-specie-item-container-selected {
  background-color: var(--deep-blue-main);
  color: var(--ivory-shade-3);
}

.Modal-add-pet-specie-item-container:focus,
  .Modal-add-pet-gender-selector-item:focus {
  outline: #2fb3b4 1px solid;
}

.Modal-add-pet-specie-item-container:hover {
  opacity: 0.8;
}

.Modal-add-pet-specie-item-container:first-of-type {
  margin: 0;
}

.Modal-add-pet-container-relative {
  position: relative;
}

.Modal-add-pet-container-absolute {
  position: absolute;
}

.Modal-add-pet-gender-selector {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
}

.Modal-add-pet-gender-selector-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Modal-add-pet-gender-selector-title {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Bold';
  font-size: 14px;
  line-height: 21px;
}

.Modal-add-pet-gender-selector-item {
  align-items: center;
  background-color: #ffffffbf;
  border-color: #b0bbbf;
  border-style: solid;
  border-width: 1px;
  color: #737a83;
  cursor: pointer;
  display: flex;
  font-family: 'IvyEpic-Regular';
  font-size: 18px;
  height: 43px;
  justify-content: center;
  outline-color: #73dad2;
  padding-top: 5px;
  transition: all ease 0.3s;
  width: 136px;
}

.Modal-add-pet-gender-selector-item {
  border-radius: 36px;
}

.Modal-add-pet-gender-selector-item:hover {
  opacity: 0.8;
}

.Modal-add-pet-gender-selector-item-selected {
  background-color: var(--deep-blue-main) !important;
  color: var(--ivory-shade-3);
}

.Modal-add-pet-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
}

.Modal-add-pet-input-container {
  background-color: transparent;
  border-radius: 0;
  border-style: none none solid none;
  border-width: 1px;
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  height: 32px;
  line-height: 24px;
  outline: unset;
  padding: 0;
  text-indent: 8px;
  transition: all ease 0.3s;
  width: 100%;
}

.Modal-add-pet-input-container[type=date] {
  text-indent: 3px;
}

.Add-pet-form-label {
  color: var(--deep-blue-main);
  display: flex;
  flex-flow: wrap;
  font-family: 'IvyEpic-Bold';
  font-size: 14px;
  line-height: 21px;
  position: relative;
}

.Add-pet-form-label-text {
  bottom: 13px;
  color: var(--hinted-text);
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  left: 8px;
  line-height: 24px;
  position: absolute;
}

.Add-pet-form-label-hide {
  color: transparent !important;
}

.Modal-add-pet-input-container::placeholder {
  color: #6d7579;
}

.Modal-add-pet-input-container:focus {
  border-color: #2fb3b4;
}

.Modal-add-pet-input-container-error {
  border-color: #cc0000;
}

.Modal-add-pet-input-container-disabled {
  color: #737a83;
}

.Modal-add-pet-footer-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 24px 0 16px 0;
  width: 320px;
}

.Modal-add-pet-footer-cancel-button {
  align-items: center;
  background-color: #eceef0;
  border-color: #eceef0;
  border-radius: 36px;
  border-style: solid;
  border-width: 1px;
  color: var(--deep-blue-main);
  cursor: pointer;
  display: flex;
  font-family: 'Inter-SemiBold';
  font-size: 16px;
  height: 60px;
  justify-content: center;
  margin-right: 16px;
  outline-color: #2fb3b4;
  width: 152px;
}

.Modal-add-pet-footer-save-button {
  align-items: center;
  background-color: var(--deep-blue-main);
  border-color: var(--deep-blue-main);
  border-radius: 36px;
  border-style: solid;
  border-width: 1px;
  color: var(--ivory-main);
  cursor: pointer;
  display: flex;
  font-family: 'Inter-SemiBold';
  font-size: 16px;
  height: 60px;
  justify-content: center;
  outline-color: #b8ede8;
  width: 152px;
}

.Modal-add-pet-get-quote-button {
  align-items: center;
  background-color: var(--deep-blue-main);
  border-radius: 36px;
  border-style: none;
  color: var(--ivory-shade-3);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: 'IvyEpic-Bold';
  font-size: 16px;
  height: 60px;
  justify-content: center;
  line-height: 24px;
  outline-color: #1ccfca;
  width: 320px;
}

.Modal-add-pet-get-quote-button:hover {
  background-color: rgb(74, 84, 93);
}


.Modal-add-pet-input-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 44px;
  width: 280px;
}

.Modal-add-pet-input-error-label {
  color: #cc0000;
  font-family: 'Inter-Medium';
  font-size: 11px;
  height: 10px;
  margin-top: 4px;
  padding-left: 10.06px;
}

.Modal-add-pet-align-self-start {
  align-self: flex-start;
  padding-left: 2.06px;
}

.Modal-add-pet-align-self-start:empty {
  display: block;
  height: 0;
}

.Modal-add-pet-form > div {
  padding-bottom: 9px;
}

.Modal-tooltip-info {
  padding-left: 8px;
}

.Modal-add-pet-footer-buttons-col {
  align-items: center;
  flex-direction: column;
}

.Modal-add-pet-terms-and-policy-wrapper {
  margin-bottom: 48px;
}

.Modal-add-pet-terms-and-privacy {
  align-items: flex-start;
  margin: auto;
  width: 100%;
}

.Modal-add-pet-terms-and-privacy input {
  background-color: var(--gray);
  border: 1px solid var(--deep-blue-shade-3);
  border-radius: 4px;
  margin-top: 0;
}

.Modal-add-pet-terms-and-privacy-error input {
  background-color: #cc00001f;
  border: 1px solid var(--error);
}

.Modal-add-pet-terms-and-privacy input:checked {
  background-color: var(--deep-blue-main);
  border: 1px solid var(--deep-blue-main);
}

.Modal-add-pet-terms-and-privacy p {
  align-items: flex-start;
  clear: none;
  color: var(--deep-blue-shade-2);
  display: unset;
  flex-wrap: wrap;
  font-family: 'IvyEpic-Regular';
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  overflow: hidden;
  white-space: pre-line;
  width: 100%;
}

.Modal-add-pet-links {
  color: var(--deep-blue-main);
  margin-right: 3px;
}

.Modal-add-pet-link-margin {
  margin: 0 0 0 3px;
}

.Modal-add-pet-input-wrapper-landing {
  width: 100%;
}

.Modal-add-pet-input-wrapper-landing .Modal-add-pet-gender-selector-item,
.Modal-add-pet-input-wrapper-landing .Modal-add-pet-specie-item-container {
  width: 156px;
}

/* Tablet - Medium */
@media (max-width: 768px) {
  .Modal-add-pet-form {
    margin: 24px 0 0;
    width: 100%;
  }

  .Modal-add-pet-footer-buttons {
    margin: 32px 0 40px 0;
  }

  .Modal-add-pet-get-quote-button {
    width: 256px;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Modal-add-pet-content {
    min-height: 543px;
    width: 288px;
  }

  .Modal-add-pet-input-container {
    padding: 0;
    width: 100%;
  }

  .Dropdown-container {
    width: 100%;
  }

  .Dropdown-list {
    width: 100%;
  }

  .Dropdown-header {
    width: 100%;
  }

  .Modal-add-pet-input-wrapper {
    width: 100%;
  }
  
  .Modal-add-pet-footer-buttons {
    align-items: center;
    flex-direction: column-reverse;
    margin: 20px 0;
    width: 100%;
  }

  .Modal-add-pet-content-landing .Modal-add-pet-footer-buttons {
    flex-direction: column;
  }
  
  .Modal-add-pet-footer-cancel-button {
    background-color: transparent;
    border: none;
    color: var(--deep-blue-shade-2);
    height: 100%;
    margin-right: 0;
    margin-top: 24px;
    text-decoration: underline;
  }

  .Modal-add-pet-footer-save-button {
    height: 60px;
    width: 280px;
  }

  .Modal-add-pet-get-quote-button {
    font-size: 16px;
    width: 100%;
  }

  .Modal-add-pet-input-wrapper-landing {
    width: 100%;
  }

  .Modal-add-pet-specie-item-container,
  .Modal-add-pet-gender-selector-item {
    width: 136px !important;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .Modal-add-pet-specie-item-container:hover {
    opacity: 0.8;
  }

  .Modal-add-pet-gender-selector-item:hover {
    opacity: 0.8;
  }  
}
