.Pet-purchase-result {
  align-items: center;
  display: flex;
  padding: 0 10px;
}

.Pet-purchase-result-failure-card {
  align-items: center;
  padding: 0 10px;
}

.Pet-flex {
  display: flex;
  padding-bottom: 16px;
}

.Pet-purchase-result-description-img {
  margin-top: 4px;
  padding-right: 8px;
}

.Pet-purchase-result-failure-card-margin {
  margin-left: 16px;
  margin-top: 3px;
}

.Pet-purchase-result-error {
  background-color: #eeb09a;
  border-radius: 8px;
  border: 2px solid #e06855;
  padding: 16px 16px 24px 16px;
  margin-bottom: 8px;
}

.Pet-purchase-result-summary {
  display: block;
}

.Pet-purchase-result-summary-col {
  flex-direction: column;
}

.Pet-purchase-result-summary-no-error {
  display: flex;
  justify-content: space-between;
}

.Pet-purchase-result > img {
  align-self: flex-start;
  margin-right: 16px;
  margin-bottom: 16px;
}

.Pet-purchase-result-name {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Regular';
  font-size: 18px;
  line-height: 27px;
  max-width: 288px;
  overflow: hidden;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Pet-purchase-result-policy-number {
  color: #293742;
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  line-height: 21px;
  margin-top: 4px;
}

.Pet-purchase-result-description-container {
  align-items: flex-start;
  align-self: center;
  display: flex;
  margin-top: 12px;
}

.Pet-purchase-result-top {
   margin-top: 12px;
}

@media (max-width: 568px) {
  .Pet-purchase-result-description-container {
    margin-left: 0;
  }
}

.Pet-purchase-result-description,
.Pet-purchase-result-description-bold {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.Pet-purchase-result-description-bold,
.Pet-purchase-result-policy-purchased {
  font-weight: 700;
}

.Pet-purchase-result-description-container > img {
  margin: 2px 8px 0 0;
}

@media (min-width: 1200px) {
  .Pet-purchase-result-left {
    padding-right: 50px;
  }
}

/* Tablet medium to bigger device sizes */
@media (min-width: 768px) {
  .Pet-purchase-result-summary-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Purchase-result>h3,
  .Purchase-result>p {
    text-align: center;
  }

  .Pet-purchase-result-summary {
    margin-top: 10px;
  }
}

@media (max-width: 568px) {
  .Pet-purchase-result-name {
    color: var(--deep-blue-main);
    display: block;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    max-width: 84px;
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap; 
  }
}


