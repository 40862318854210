.Footer-Copyright {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.Footer-Copyright span {
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin: 0 32px 40px;
  text-align: center;
}
