.Shimmer {
  animation : shimmer 30s infinite;
  background-size: 300px 100%;
  background: linear-gradient(to right, #b7bec5 4%, var(--ivory-shade-1) 25%, var(--ivory-shade-2) 36%);
  color: transparent !important;  
  display: flex;
}

.Shimmer > * {
  color: transparent !important;
  display: none !important;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
