.Vip-support-main-container {
  align-items: center;
  background-color: var(--deep-blue-main);
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.Vip-support-title {
  color: var(--citron-main);
  font-family: "IvyPresto-Regular";
  font-size: 28px;
  font-weight: 400;
  line-height: 31px;
  margin: 0;
  text-align: center;
}

.Vip-support-description {
  color: var(--ivory-main);
  font-family: "IvyEpic-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  margin-top: 24px;
  text-align: center;
}

.Vip-support-schedule {
  color: var(--ivory-main);
  font-family: "IvyEpic-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-top: 7px;
  text-align: center;
  width: 190px;
}
