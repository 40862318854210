
.Quote-content-image-1 {
  height: 140px;
  position: absolute;
  left: -300px;
  top: 628px;
  width: 256px;
}

.Quote-content-image-2 {
  height: 218px;
  right: -590px;
  position: absolute;
  bottom: 0;
  width: 256px;
}

.Quote-content-image-10 {
  height: 150px;
  left: 780px;
  position: absolute;
  top: 1289px;
  width: 256px;
}

/* not even a tablet :| */
@media (max-width: 1340px) {
  .Quote-content-image-1 {
    height: auto;
    left: -200px;
    width: 200px;
  }

  .Quote-content-image-2 {
    height: auto;
    right: -520px;
    width: 200px;
  }
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Quote-content-image-1 {
    display: none;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Quote-content-image-1 {
    display: none;
  }
}
