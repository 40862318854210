.Your-info-footer-container {
  display: flex;
  height: 60px;
  justify-content: center;
  margin: 24px 0 40px 0;
  width: 100%;
}

.Your-info-return-to-plans-button {
  align-self: center;
  cursor: pointer;
  display: flex;
  outline-color: #2fb3b4;
}

.Your-info-return-to-plans-arrow {
  margin-right: 10px;
  pointer-events: none;
  width: 10px;
}

.Your-info-return-to-plans-label {
  align-self: center;
  color: #6d7579;
  font-family: 'Inter-SemiBold';
  font-size: 16px;
  height: auto;
  pointer-events: none;
}

.Your-info-continue-to-payments-button {
  align-items: center;
  background-color: var(--deep-blue-main) !important;
  border-radius: 30px;
  color: var(--ivory-shade-3);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 39px;
  min-height: 60px;
  outline-color: #2fb3b4;
  text-align: center;
  width: 187px;
}

.Your-info-continue-to-payments-label {
  border-style: none;
  font-family: 'Inter-SemiBold';
  font-size: 16px;
  margin: 0 24px 0 40px;
  pointer-events: none;
}

.Your-info-continue-to-payments-arrow,
.Your-info-continue-to-step-arrow {
  height: 16px;
  pointer-events: none;
  width: 10px;
}

.Your-info-continue-to-step-arrow {
  transform: rotate(90deg);
}

.Yur-info-continue-to-step-arrow {
  height: 10px;
  pointer-events: none;
  width: 16px;
}

.Your-first-payment {
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--midnight-200);
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  margin: 42px auto;
  overflow: hidden;
  padding: 29px 16px 29px 16px;
  width: 91%;
}

.Your-first-payment img {
  padding-right: 13px;
}

.Your-first-payment span {
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  margin: 0;
}

.Your-first-payment p {
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  margin: 0;
}

.Your-info-each-month {
  font-family: 'IvyEpic-Bold' !important;
}

.Your-info-total-container {
  align-items: center;
  justify-content: space-between;
  width: 40%;
}

.Your-info-total {
  color: var(--deep-blue-main) !important;
  font-family: 'IvyEpic-Bold' !important;
  font-size: 22px !important;
}

.Your-info-flex {
  display: flex;
}

.Your-info-agreements-container {
  background-color: var(--ivory-shade-3);
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 407px;
}

.Your-info-agreements-container p {
  margin: 0;
}

.Your-info-agreements-container label {
  align-items: center;
  color: var(--deep-blue-shade-2);
  cursor: pointer;
  display: flex;
  font-family: 'IvyEpic-Regular';
  font-size: 12px;
  padding-bottom: 8px;
}

.Your-info-calendar-container {
  font-family: 'Inter-Bold';
  font-size: 16px;
  position: relative;
}

.Your-info-calendar-container span {
  color: var(--ivory-main);
  font-size: 16px;
  position: absolute;
  top: 15px;
}

.Your-info-calendar-two {
  font-family: 'Inter-Bold' !important;
  left: 9px;
}

.Your-info-calendar-one {
  font-family: 'Inter-Bold' !important;
  left: 14px;
}

.Your-info-calendar-three {
  font-family: 'Inter-Black' !important;
  font-size: 14px !important;
  left: 4px;
}

.Your-info-total-payment-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 40%;
}

.Your-info-footer-buttons-container {
  display: flex;
  height: 60px;
  justify-content: center;
  margin-top: 32px;
  width: 100%;
}

.grecaptcha-badge {
  z-index: 2;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Your-first-payment {
    margin: 42px auto;
    width: 95%;
  }

  .Your-info-footer-container-enrollment {
    flex-direction: column;
    height: auto;
    margin-top: 32px;
    margin: 0;
  }

  .Your-info-continue-to-payments-button {
    width: 187px;
  }

  .Your-info-footer-buttons-container {
    margin-bottom: 36px;
  }
}

/* Tablet - Medium */
@media (max-width: 710px) {
  .Your-first-payment {
    margin: 42px 0;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Your-info-footer-container {
    align-items: center;
    flex-direction: column;
    height: auto;
    margin: 32px 0 10px 0;
    text-align: center;
    width: 100%;
  }

  .Your-info-footer-container-enrollment {
    margin-bottom: 0;
  }

  .Your-info-return-to-plans-button {
    margin-right: 0;
    order: 2;
  }

  .Your-info-return-to-plans-button-enrollment {
    margin-bottom: 36px;
  }

  .Your-info-continue-to-payments-button {
    order: 1;
    margin: 0 0 20px 0;
  }

  .Your-first-payment {
    box-shadow: none;
    flex-direction: column;
    margin: 0 auto;
    padding: 19px 16px 16px 16px;
    width: auto;
  }

  .Your-first-payment img {
    height: 38px;
    padding-right: 24%;
    width: 33.17px;
  }

  .Your-info-calendar-one {
    left: 13px;
  }

  .Your-info-calendar-two {
    left: 8px;
  }

  .Your-info-calendar-three {
    left: 3px;
  }

  .Your-info-flex {
    order: 2;
  }

  .Your-first-payment .Your-info-flex {
    width: 100%;
  }

  .Your-info-flex p {
    margin-left: 11.83px;
  }

  .Your-info-total-container {
    margin-bottom: 16px;
    width: 100%;
  }

  .Your-info-total-payment-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
    width: 100%;
  }

  .Your-info-agreements-container {
    margin: 0 15px 0 21px;
    width: 90%;
  }

  .Questions-innerent {
    order: 4;
  }

  .Sidebar-help-links-container {
    padding: 0;
    width: 100%;
  }

  .Quoting-footer-container {
    margin-bottom: 0;
  }

  .Your-info-footer-buttons-container {
    align-items: center;
    flex-direction: column;
    margin-bottom: 36px;
  }
}
