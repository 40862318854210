.Purchase-result-container {
  flex-direction: column;
  margin: -90px 0 20px;
  padding: 64px;
  top: -100px;
  width: 858px;
}

.Purchase-result-success-container {
  width: 778px;
}

.Purchase-result-success-container .Purchase-result {
  max-width: 100%;
}

.Purchase-result-success-container .Purchase-result-list {
  max-width: 670px;
}

.Purchase-result-fail-container-ul {
  background-color: var(--ivory-shade-1);
  border-radius: 8px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  padding-bottom: 30px;
}

.Purchase-result-container h1 {
  color: var(--deep-blue-main);
  font-family: 'IvyPresto-Regular';
  font-size: 36px;
  line-height: 1;
  letter-spacing: -0.4px;
  margin: 0 0 20px;
}

.Purchase-result-text {
  color: #3d484d;
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  text-align: justify;
  width: 97%;
}

.Purchase-result-content {
  display: flex;
  justify-content: space-between;
}

.Purchase-result-list {
  list-style-type: none;
  padding-right: 15px;
}

.Purchase-result-list-small {
  padding-top: 8px;
}

.Purchase-result-list>li+li {
  margin-top: 16px;
}

.Purchase-result-list>ul, .Purchase-result-list div>ul {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 18px;
}

.Purchase-result-list>ul>li {
  width: 100%;
}

.Purchase-result-container hr {
  background-color: #6d7579;
  margin: 0;
  width: 100%;
}

.PurchaseSuccess-container-right {
  height: 485px;
  margin-bottom: 0px;
  padding-top: 32px;
  width: 512px;
}

.PurchaseSuccess-container-row-bottom {
  align-content: center;
  align-items: center;
  display: flex;
  flex-basis: auto;
  flex-direction: row;
  margin-top: 64px;
}

.PurchaseSuccess-google-play {
  height: 44px;
  margin-right: 32px;
  width: 145px;
}

.PurchaseSuccess-app-store {
  height: 44px;
  margin-right: 16px;
  width: 145px;
}

.Purchase-result-whats-next {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Regular';
  font-size: 22px;
  font-weight: 700;
  line-height: 1.143;
  margin: 20px 0 16px 0;
}

.Purchase-result-whats-next-icon {
  height: 20px;
  width: 20px;
}

.Purchase-result-whats-next-description {
  color: #1e2226;
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 40px;
}

.Purchase-result-whats-next-cards {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 200px;
  max-width: 906px;
}

.Purchase-result-whats-next-card {
  margin-bottom: 32px;
  margin-right: 16px;
  min-width: 300px;
  padding: 32px;
}

.Purchase-result-card {
  background-color: #fbf6f1;
  border-radius: 16px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
  display: flex;
}

.Purchase-result-card-flex1 {
  background-color: #fbf6f1;
  border-radius: 16px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex: 1;
}

.Purchase-retry-card-by-payment {
  background-color: #fbf6f1;
  border-radius: 16px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
  display: flex;
}

.Purchase-whats-next-card-separator {
  margin: 16px;
}

.Pet-cloud-section-row {
  flex-direction: row !important;
}

.Purchase-close-button {
  align-self: center;
  background-color: var(--deep-blue-main);
  border-radius: 8px;
  color: #fff;
  font-family: 'Inter-SemiBold';
  font-size: 16px;
  height: 60px;
  margin-top: 40px;
  width: 82%;
}

/* Desktop */
@media (min-width: 1201px) {
  .Purchase-result-content {
    padding-bottom: 40px;
  }
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Purchase-result-container {
    flex-direction: column;
    height: fit-content;
    margin: -30px 0;
    width: 70.5%;
  }

  .Purchase-result-content {
    flex-direction: column;
  }

  .PurchaseSuccess-container-right {
    height: fit-content;
    padding: 64px;
    width: auto;
  }

  .Purchase-ballon {
    position: absolute;
    right: 63.42px;
    top: -56px;
  }

  .Purchase-result-whats-next {
    margin-top: 120px;
  }

  .Purchase-result-whats-next-cards {
    width: 85%;
  }

  .Purchase-result-whats-next-card {
    padding: 32px 24px;
  }

  .Purchase-result-whats-next-description {
    margin-bottom: 32px;
    width: 85%;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Pet-cloud-section-no-fully-success {
    background-color: #fbf6f1;
    border-radius: 20px;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 5%);
    padding: 2rem;
  }

  .Pet-cloud-section-no-fully-success hr {
    display: none;
  }

  .Pet-cloud-section-no-fully-success .Pet-cloud-section-as-card {
    margin-top: 0;
  }

  .Purchase-result-container {
    flex-direction: column;
    height: auto;
    margin: 0;
    padding: 0;
    position: unset;
    width: 100%;
  }

  .Purchase-result-container h1 {
    font-size: 22px;
  }

  .Purchase-result-content {
    flex-direction: column;
  }

  .Purchase-ballon {
    position: absolute;
    right: 0px;
    top: -40px;
  }

  .PurchaseSuccess-container-right {
    height: auto;
    padding: 20px;
    width: 88%;
  }

  .PurchaseSuccess-container-row-bottom {
    flex-wrap: wrap;
    height: auto;
    justify-content: center;
    margin: auto;
    width: 70%;
  }

  .PurchaseSuccess-app-store {
    margin: auto;
  }

  .PurchaseSuccess-google-play {
    margin: 16px auto 21px;
  }

  .Purchase-result-whats-next-description {
    text-align: center;
    width: 85%;
  }

  .Purchase-result-whats-next-cards {
    align-items: center;
    flex-direction: column;
  }

  .Purchase-result-whats-next-card {
    margin-right: 0;
    min-width: unset;
    padding: 32px 16px;
  }

  .Purchase-result-card {
    background-color: unset;
    box-shadow: unset;
  }

  .Purchase-result-card-flex1 {
    background-color: unset;
    box-shadow: unset;
  }

  .Purchase-result-card-mobile {
    background-color: #fbf6f1;
    border-radius: 20px;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 5%);
  }

  .Pet-purchase-result-left p,
  .Pet-purchase-result-left h1 {
    text-align: center;
  }

  .Purchase-result-list {
    overflow: unset;
    max-height: unset;
    padding-right: 0px;
  }

  .Purchase-result {
    padding: 16px;
    margin: -16px;
  }

  .Purchase-result-whats-next {
    margin: 20px;
    padding: 20px;
  }
}
