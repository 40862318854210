.Selector-compo-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 5px;
}

.Selector-compo-title {
  display: flex;
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 3px;
}

.Selector-compo-select {
  align-items: center;
  background-color: #fff;
  border-radius: 24px;
  border: 1px solid var(--deep-blue-main);
  display: flex;
  height: 40px;
  justify-content: space-between;
  margin-top: 4px;
  position: relative;
  width: 136px;
}

.Selector-compo-select select {
  padding-left: 15px;
  height: 40px;
}

.Selector-compo-select img {
  padding-right: 15px;
}

.Selector-compo-select:has(select:focus) {
  border: 1px solid var(--deep-blue-main);
}

.Selector-compo-select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  color: #000;
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  transition: all 0.25s ease;
  width: 100%;
  align-self: center;
  padding-left: 5px;
  background-color: transparent;
}

.Selector-compo-container-overlay {
  background-color: transparent;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.Selector-compo-container-over-div {
  display: flex;
  height: 85px;
  position: relative;
  width: 100%;
}

.Selector-custom-container {
  align-items: normal;
  background-color: #fff;
  border-radius: 24px;
  border: 1px solid var(--deep-blue-main);
  display: flex;
  flex-direction: column;
  min-height: 40px;
  transition: all 0.25s ease;
  width: 136px; 
}

.Selector-custom-container[data-open=true] {
  border-radius: 24px 24px 0 0;
}

.Selector-compo-container .Dropdown-common-header {
  border: none;
}

.Selector-custom-container-list {
  background-color: #fff;
  border-color: var(--deep-blue-main);
  border-radius: 0 0 24px 24px;
  border-style: none solid solid;
  border-width: 1px;
  margin-left: -0.5px;
  margin-top: 41px;
  padding-bottom: 0px;
  width: 100%;
}

.Selector-custom-container-list li {
  background-color: #fff;
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  height: 40px;
  margin: 0;
  padding: 0 16px 0 9px;
  border-top: 1px solid var(--deep-blue-main);
}

.Selector-custom-container .Dropdown-common-list-item {
  background-color: transparent;
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
}

.Selector-custom-container-list .Dropdown-common-list-item-container:hover,
.Selector-custom-container-list .Dropdown-common-list-item-container[data-selected=true] {
  background-color: var(--citron-shade-3);
}

.Select-custom-disabled span {
  color: var(--deep-blue-shade-3)
}

.Select-custom-disabled span:hover,
.Select-custom-disabled span:focus,
.Select-custom-disabled span:active {
  background-color: unset;
  cursor: default !important;
}

.Selector-custom-tool-tip {
  border: 1px solid var(--deep-blue-main);
}

/* Mobile */
@media(max-width: 568px) {
  .Selector-custom-container {
    width: 100%;
  }

  .Selector-compo-container {
    align-items: flex-start;
    margin-bottom: 20px;
    min-width: 100%;
  }

  .Selector-compo-title {
    margin-left: 20px;
  }

  .DeductibleReimbursement-container {
    min-height: 190px !important;
    width: 100% !important;
  }

  .DeductibleReimbursement-container-div {
    flex-wrap: wrap;
  }
}