.Purchase-sticky-bar-container {
  bottom: 0;
  display: block;
  position: fixed;
  width: 100%;
  z-index: 10;
}

.Purchase-sticky-bar-price-inner-container {
  align-items: center;
  background-color: var(--citron-main);
  display: flex;
  gap: 8px;
  justify-content: center;
  padding: 24px;
}

.Purchase-sticky-bar-purchase-button,
.Purchase-sticky-bar-back-button {
  align-items: center;
  background-color: var(--deep-blue-main);
  border-color: var(--deep-blue-main);
  border-radius: 30px;
  border-style: solid;
  border-width: 1px;
  color: var(--ivory-shade-3);
  cursor: pointer;
  display: flex;
  font-family: 'Inter-SemiBold';
  font-size: 16px;
  height: 48px;
  justify-content: center;
  line-height: 19.36px;
  padding: 12.5px 48px;
  width: 189px;
}

.Purchase-sticky-bar-purchase-button:focus,
.Purchase-sticky-bar-back-button:focus {
  outline-color: var(--deep-blue-main);
}

.Purchase-sticky-bar-purchase-button-disabled {
  background-color: var(--deep-blue-shade-3);
  border-color: var(--deep-blue-shade-3);
  color: var(--solid-white);
}

.Purchase-sticky-bar-back-button {
  background-color: var(--citron-main);
  border-color: var(--solid-black);
  color: var(--solid-black);
  width: 135px;
}

.Purchase-sticky-bar-button-icon {
  height: 16px;
  margin-left: 10px;
  width: 10px;
}
