.Coming-soon-button-container {
  width: 404px;
}

.Coming-soon-input-container {
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: space-between;
  width: auto;
}

.Coming-soon-input-email-container {
  display: flex;
  flex-direction: column;
  height: 60px;
  width: 264px;
}

.Coming-soon-input-text {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Bold';
  font-size: 14px;
  padding-left: 8px;
}

.Coming-soon-container-class {
  height: 20px;
  margin-left: 10px;
  margin-top: 4px;
}

.Coming-soon-input-email {
  width: auto !important;
}

.Coming-soon-notify-user-primary-button {
  background-color: var(--deep-blue-main);
  border-radius: 8px;
  border: none;
  cursor: pointer;
  height: 60px;
  width: 116px;
}

.Coming-soon-notify-user-primary-button-text {
  color: var(--ivory-shade-3);
  font-family: 'IvyEpic-Bold';
  font-size: 16px;
}

.Coming-soon-button-check-price {
  align-items: center;
  background-color: var(--deep-blue-main);
  border-radius: 8px;
  border: none;
  cursor: pointer;
  display: flex;
  height: 60px;
  justify-content: center;
}

.Coming-soon-button-check-price > span {
  padding: 0 20px;
}

/* Mobile */
@media(max-width: 568px) {
  .Coming-soon-button-container {
    width: 100%;
  }

  .Coming-soon-input-email-container {
    width: 60%;
  }

  .Coming-soon-input-container {
    padding: 0 10px;
  }
}
