.Billing-cycle-title  {
  align-items: flex-start;
  color: var(--deep-blue-main);
  display: flex;
  font-family: 'IvyPresto-Regular';
  font-size: 36px;
  margin: 0 0 24px 0;
  text-align: left;
  width: 100%;
}

.Billing-cycle-section {
  margin-bottom: 40px;
  width: 736px;
}

.Billing-cycle-section .Billing-cycle-div:first-child {
  margin: 0 16px 0 0;
}

.Billing-cycle-section .Billing-cycle-div:last-child {
  margin-right: 0;
}

.Billing-cycle-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.Billing-cycle-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.Billing-cycle-div {
  background-color:rgba(255, 255, 255, 0.75);
  border-radius: 8px;
  border: 1px solid var(--deep-blue-shade-3);
  padding: 16px;
  outline-color: var(--deep-blue-main);
}

.Billing-cycle-div:focus {
  border: 2px solid var(--deep-blue-main);
}

.Billing-cycle-div:hover {
  cursor: pointer;
}

.Billing-cycle-div div {
  pointer-events: none;
}

.Billing-cycle-plan-container {
  width: 100%;
}

.Billing-cycle-plan-title {
  align-items: flex-start;
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic-Bold';
  font-size: 16px;
  justify-content: space-between;
  margin: 0 0 3px 0;
  text-align: center;
  width: auto;
}

.Billing-cycle-plan-container div label {
  margin: 0;
}

.Billing-cycle-price {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Bold';
  justify-content: flex-start;
}

.Billing-cycle-price-mobile {
  display: none;
}

.Billing-cycle-price-currency-cent {
  font-size: 14px;
}

.Billing-cycle-price-dollar {
  font-size: 22px;
  margin: -2px 0 0 2px;
}

.Billing-cycle-price-tag {
  align-self: flex-end;
  color: var(--deep-blue-main);
  display: flex;
  font-size: 12px;
  margin: 0 0 4px 3px;
}

.Billing-cycle-additional-fee {
  align-items: flex-end;
  margin-top: 40px;
  justify-content: flex-start;
  text-align: start;
}

.Billing-cycle-additional-fee span {
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic-Regular';
  font-size: 12px;
}

.Billing-cycle-selected {
  background-color: var(--blue-shade-3);
  border: 2px solid var(--deep-blue-main);
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Billing-cycle-div {
    max-width: unset;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Billing-cycle-title  {
    font-size: 24px;
    text-align: center;
  }

  .Billing-cycle-row {
    align-items: center;
    flex-direction: column;
  }

  .Billing-cycle-div,
  .Billing-cycle-div:first-child {
    height: 86px;
    margin: 0 0 16px 0 !important;
    min-width: 328px;
    width: 100%;
  }

  .Billing-cycle-section {
    margin-bottom: 16px;
    width: 100%;
  }

  .Billing-cycle-plan-container {
    margin: 12px 8px 12px 12px;
  }

  .Billing-cycle-row .Billing-cycle-plan-title {
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: 12px;
  }

  .Billing-cycle-price {
    flex-direction: row;
    width: fit-content;
  }

  .Billing-cycle-plan-container > .Billing-cycle-price {
    display: none;
  }

  .Billing-cycle-price-mobile,
  .Billing-cycle-fee-container {
    align-items: flex-start;
    display: flex;
    margin-right: 10px;
    width: fit-content;
  }

  .Billing-cycle-price-tag {
    font-family: 'Inter-Regular';
    margin-bottom: 3px;
    width: max-content;
  }

  .Billing-cycle-additional-fee {
    align-items: flex-start;
    height: 60%;
  }
}
