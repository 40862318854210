.Header-container {
  align-items: center;
  background-color: var(--header-background);
  display: flex;
  height: 33px;
  justify-content: center;
  max-height: 33px;
  width: 100%;
}

.Header-container > span {
  color: #fff;
  height: fit-content;
  text-align: center;
  font: 14px 'Inter-Regular';
}

/* Mobile */
@media (max-width: 568px) {
  .Header-container > span {
    font-size: 10px;
    width: 80%;
  }
}
