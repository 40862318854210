.Landing-left-container {
  overflow: hidden;
  padding-right: 50px;
  position: relative;
  z-index: 1;
}

.Landing-welcome-title {
  color: var(--deep-blue-main);
  font-family: 'IvyPresto-Regular';
  font-size: 50px;
  letter-spacing: -0.5px;
  line-height: 1.25;
  margin: 0;
}

.Landing-pet-insurance-sub-text,
.Landing-pet-insurance-detail-text,
.Landing-pet-insurance-detail-text-mobile {
  color: #293742;
  font-family: 'IvyEpic-Regular';
  font-size: 18px;
  line-height: 27px;
}

.Landing-pet-insurance-sub-text {
  margin: 32px 0 20px 0;
  width: 544px;
}

.Landing-pet-insurance-detail-text-mobile {
  display: none;
}

.Landing-pet-insurance-detail-text {
  margin: 0 0 32px 0;
  width: 419px;
}

.Landing-partner-row-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 44px;
  width: 100%;
}

.Landing-partner-information-row {
  align-items: center;
  display: flex;
  margin-bottom: 19px;
  max-width: 575px;
  width: 100%;
}

.Landing-discount-tag-image {
  height: 44px;
  min-width: 70px;
  width: 70px;
}

.Landing-discount-text-container {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.Landing-discount-text {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Bold';
  font-size: 18px;
  line-height: 27px;
}

.Landing-discount-value {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  line-height: 24px;
  margin-top: 3px;
}

.Landing-bottom-left-circle {
  height: 566px;
  left: 330px;
  opacity: 0.5;
  overflow: hidden;
  position: absolute;
  top: -623px;
  width: 566px;
}

.Landing-coverage-information-text {
  color: #3d484d;
  font-size: 14px;
  line-height: 1.42;
  margin-top: 13px;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Landing-bottom-left-circle {
    display: none;
  }

  .Landing-left-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .Landing-welcome-title {
    margin-top: 32.44px;
    text-align: center;
  }

  .Landing-pet-insurance-sub-text {
    margin-top: 32px;
    text-align: center;
  }

  .Landing-pet-insurance-detail-text {
    text-align: center;
  }

  .Landing-partner-information-row {
    width: 419px;
  }
  
  .Landing-coverage-information-text {
    text-align: center;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Landing-welcome-title {
    font-size: 32px;
    line-height: 1.375;
    margin-top: 28px;
  }

  .Landing-pet-insurance-sub-text {
    font-size: 16px;
    margin-bottom: 32px;
    width: 90%;
  }

  .Landing-pet-insurance-detail-text-mobile {
    display: flex;
    font-size: 14px;
    margin-top: 56px;
    width: 292px;
  }

  .Landing-pet-insurance-detail-text {
    font-size: 16px;
    width: 90%;
  }
  
  .Landing-left-card {
    height: 78px;
    margin: 40px 0 42px 0;
    width: 90%;
  }

  .Landing-discount-text,
  .Landing-discount-value {
    font-size: 14px;
  }

  .Landing-discount-value {
    margin-top: 4px;
  }

  .Landing-partner-information-row {
    width: 90%;
  }

  .Landing-partner-row-container {
    margin-bottom: 8px;
  }
}

/* Micro-Mobile */
@media (max-width: 319px) {
  .Landing-welcome-title {
    font-size: 10vw;
  }
}
