.Your-info-main-container {
  background-color: var(--ivory-40);
  border-radius: 12px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  height: 100%;
  margin-bottom: 40px;
  margin-top: -89px;
  padding-bottom: 38px;
  position: relative;
  width: 736px;
}

.Your-info-content {
  align-items: center;
  background-image: url(../../../assets/bg.png);
  background-size: cover;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 2;
}

.Your-info-step-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 672px;
  padding: 32px;
}

.Your-info-pickup-content {
  padding: 0 60px;
  max-width: 608px;
}

.Your-info-pickup-content h3 {
  color: var(--deep-blue-main);
  margin: 16px 0;
  font-family: 'IvyPresto-Regular';
  font-size: 36px;
  text-align: center;
}

.Your-info-pickup-content p {
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  text-align: center;
}

.Your-info-pickup-content p:last-child {
  font-style: normal;
}

.Your-info-pickup-content p>a {
  color: #0563c1;
}

.Your-info-pickup-date-labe {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Bold';
  font-size: 14px;
  line-height: 21px;
  position: relative;
}

.Your-info-pickup-date-input {
  background-color: transparent;
  border-radius: 0;
  border-style: none none solid none;
  border-width: 1px;
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  height: 32px;
  line-height: 24px;
  outline: unset;
  padding: 0;
  text-indent: 3px;
  transition: all ease 0.3s;
  width: 100%;
}

.Your-info-pickup-date-input-error {
  border-color: #cc0000;
}

.Your-info-pickup-date-input::placeholder {
  color: #6d7579;
}

.Your-info-pickup-date-input:focus {
  border-color: #2fb3b4;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Your-info-main-container {
    margin-bottom: 0;
    margin-top: 0;
    padding: 0 0 40px;
    width: 100%;
  }
}

/* Tablet - Medium */
@media (max-width: 710px) {
  .Your-info-main-container {
    width: 100%;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Your-info-main-container {
    border-radius: 0;
    width: 100%;
  }

  .Your-info-step-container {
    justify-content: center;
  }

  .Your-info-pickup-date-input {
    padding: 0;
    width: 100%;
  }
}
