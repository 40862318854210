.Your-info-image-1 {
  height: 240px;
  position: absolute;
  right: -273px;
  top: 300px;
  width: 256px;
}

.Your-info-image-1-lower {
  top: 650px;
}

.Your-info-image-2 {
  bottom: 0;
  height: 218px;
  left: -403px;
  position: absolute;
  width: 256px;
}

.Your-info-image-3 {
  bottom: 0;
  height: 240px;
  position: absolute;
  right: -300px;
  width: 256px;
}

.Your-info-image-4 {
  bottom: 48px;
  height: 257px;
  left: -303px;
  position: absolute;
  width: 256px;
}

/* not even a tablet :| */
@media (max-width: 1340px) {
  .Your-info-image-1 {
    height: auto;
    right: -230px;
    width: 200px;
  }
  
  .Your-info-image-2 {
    height: auto;
    left: -200px;
    width: 200px;
  }
  
  .Your-info-image-3 {
    height: auto;
    right: -230px;
    width: 200px;
  }
  
  .Your-info-image-4 {
    height: auto;
    left: -200px;
    width: 200px;
  }
}
