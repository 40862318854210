.Costco-membership-container {
  text-align: center;
  width: 100%;
}

.Costco-membership-header {
  color: var(--deep-blue-main);
  display: flex;
  font-family: 'IvyPresto-Regular';
  font-size: 36px;
  letter-spacing: -0.4px;
  text-align: center;
}

.Costco-membership-text {
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  text-align: left;
}

.Costco-membership-no-discount a,
.Costco-membership-text a {
  color: #0563c1;
  font-family: 'IvyEpic-Regular';
  text-decoration: none;
}

.Costco-membership-input-container {
  background-color: rgba(184, 237, 232, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin: 32px auto 24px;
  max-width: 289px;
  padding: 20px 31px 10px 32px;
  text-align: left;
}

.Costco-membership-row {
  display: flex;
}

.Costco-membership-row button {
  align-self: flex-end;
}
 
.Costco-membership-input {
  color: var(--deep-blue-main);
  display: flex;
  flex-direction: column;
  font-family: 'Inter-regular';
  font-size: 13px;
  font-weight: 500;
  max-width: 186px;
}

.Costco-membership-input input {
  border-radius: 4px;
  border: 1pt solid #d9dee1;
  color: #22435e;
  height: 15px;
  margin-top: 8px;
  padding: 10px 16px;
  width: 151px;
}

.Costco-membership-input input::placeholder {
  color: #b0bbbf;
  font-size: 14px;
  font-weight: 400;
}

.Costco-membership-input input:disabled {
  background-color: #fff;
  border: 1pt solid #d9dee1;
  color: #6d7579;
}

.Costco-membership-input-container button {
  margin: 10px 0 0 16px;
}

.Costco-membership-button {
  background-color: #22435e;
  border-radius: 5px;
  color: var(--ivory-shade-3);
  font-family: 'Inter-Regular';
  font-weight: 500;
  width: 87px;
}

.Costco-membership-edit-button {
  align-self: center !important;
  background: none;
  color: #3d484d;
  height: auto;
  margin: 17px 0 0 16px !important;
  padding: 0;
}

.Costco-membership-error {
  color: #cc0000;
  font-family: 'Inter-regular';
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.2px;
  margin-top: 2px;
  min-height: 14px;
}

.Costco-membership-find-number {
  color: #60727b;
  font-family: 'IvyEpic-Regular';
  font-size: 10px;
}

.Costco-membership-no-discount {
  color: #3d484d;
  font-family: 'IvyEpic-Bold';
  font-size: 14px;
  margin-top: 80px;
}

.Costco-logos-container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.Costco-membership-logo {
  height: 32px;
  margin: 0px 2px;
  width: 50px;
}

.Costco-membership-selected {
  align-items: center;
  display: flex;
  justify-content: center;
}

.Costco-membership-verified {
  color: #22435e;
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  margin-left: 16px;
}

.Costco-membership-no-discount-link {
  background: none;
  color: #0563c1;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
}

@media (max-width: 400px) {
  .Costco-membership-input-container {
    flex-direction: column;
  }
}
