.Tooltip-container {
  position: relative;
}

.Tooltip-icon-default {
  cursor: pointer;
}

.Tooltip-main-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 200px;
  pointer-events: none;
  position: absolute;
  width: auto;
  z-index: 1;
}

.Tooltip-description-container {
  align-items: center;
  background-color: #b8ede8;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 13px 16px;
  width: 100%;
}

.Tooltip-description {
  color: #1e2226 !important;
  font-family: 'Inter-Regular' !important;
  font-size: 14px !important;
  text-align: center;
}

.Tooltip-arrow-container {
  display: flex;
  height: 10%;
  width: 100%;
}

.Tooltip-arrow-start {
  justify-content: flex-start;
  padding-left: 40px;
}

.Tooltip-arrow-end {
  justify-content: flex-end;
  margin-right: 40px;
}

.Tooltip-arrow-center {
  justify-content: center;
}

.Tooltip-arrow-down {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 13px solid #b8ede8;
  height: 0;
  width: 0;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Tooltip-main-container {
    height: auto;
    max-width: auto;
    min-width: auto;
    width: auto;
  }

  .Tooltip-description {
    font-size: 10px !important;
    overflow-wrap: break-word;
    overflow: auto;
  }

  .Tooltip-description-container {
    bottom: 13px;
    left: -16px;
    padding: 10px 10px;
    position: absolute;
    width: auto;
  }

  .Tooltip-arrow-start {
    padding: 0;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Tooltip-arrow-start {
    padding: 0;
  }

  .Tooltip-description-container {
    bottom: 13px;
    left: -32px;
    padding: 10px 10px;
    position: absolute;
    width: auto;
  }

  .Tooltip-arrow-down {
    bottom: 1px;
    position: relative;
  }

  .Tooltip-description {
    max-width: 200px;
    width: max-content;
  }
}
