.Quoting-footer-container {
  align-items: center;
  background-color: #002028;
  color: var(--ivory-main);
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 40px 0 40px 0;
  text-align: center;
  width: 100%;
}

.Quoting-footer-content {
  max-width: 1119px;
}

.Quoting-footer-container a {
  color: var(--ivory-main);
  font-size: 16px;
  display: inline-block;
  text-decoration: none;
}

.Quoting-footer-text {
  color: var(--ivory-main);
  font-family: "IvyEpic-Regular";
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  text-align: justify;
}

.Quoting-footer-text-header {
  color: var(--ivory-main);
  font-family: "IvyEpic-Regular";
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.Quoting-footer-text-spacing {
  margin: 8px 0 32px !important;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Quoting-footer-container {
    padding: 27px 0 31px 0;
  }

  .Quoting-footer-content {
    width: 95%;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Quoting-footer-content {
    margin-top: 0;
    padding: 0;
    width: 90%;
  }

  .Quoting-footer-text-spacing {
    padding: 0 !important;
  }

  .Quoting-footer-text {
    margin-top: 24px;
  }

  .Quoting-footer-text-header {
    margin-bottom: 8px;
  }
}
