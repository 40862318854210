.Landing-logo-image {
  height: 47px;
  margin: 40px 0 0 40px;
  width: 140px;
  z-index: 1;
}

.Landing-logo-image-partner {
  margin: 32px 0 0 0;
  height: 64px;
  width: 161px;
}

.Landing-ballon-partner {
  margin: -20px 0 0 28.32px;
}

.Landing-header-container {
  background-color: var(--ivory-shade-3);
  display: flex;
  flex-direction: column;
}

.Landing-header-top-disclaimer {
  align-items: center;
  background-color: #005Dab;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  z-index: 4;
}

.Landing-header-top-disclaimer>div {
  display: flex;
  text-align: center;
  width: 100%;
}

.Landing-header-top-disclaimer>div>span {
  color: #fff;
  font-family: 'Helvetica';
  font-size: 16px;
  margin: 13px 66px 12px 66px;
}

.Landing-header-top-disclaimer>div>span>a {
  color: #fff;
  text-decoration: underline;
}

.Landing-header-top-footer {
  background-color: #ffc529;
  border-color: #ffc529;
  border-style: solid;
  border-width: 1px;
  height: 3px;
  width: 100%;
}

.Landing-header {
  display: flex;
  justify-content: space-between;
}

.Landing-header-partner {
  align-self: center;
  justify-content: space-between;
  margin: 0 100px;
  width: 1000px;
}

.Landing-partner-logo {
  height: 52px;
  margin: 27px -100px 0 0;
  min-width: 179px;
  z-index: 3;
}

.Landing-partner-logo-usaa {
  height: 85px;
  width: auto;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Landing-top-right-circle {
    right: 6px;
    top: -32px;
  }

  .Landing-bottom-white-circle-container {
    display: unset;
    height: 475px;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 454px;
    width: 333px;
  }

  .Landing-logo-image {
    z-index: 1;
  }

  .Landing-header-left {
    justify-content: flex-start;
  }

  .Landing-header-top-disclaimer {
    height: fit-content;
  }

  .Landing-header-partner {
    margin: 0 3% 0 3%;
    width: 95%;
  }

  .Landing-partner-logo {
    margin-right: 0;
  }

  .Landing-header-top-disclaimer>div>span {
    font-size: 10px;
  }
}

/* Tablet - Medium */
@media (max-width: 768px) {
  .Landing-logo-image {
    width: 179.5px;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Landing-logo-image {
    height: 32.1px;
    margin: 32px 0 0 12px;
    width: 118.71px;
  }

  .Landing-bottom-white-circle-container {
    display: none;
  }

  .Landing-ballon-partner {
    display: none;
  }

  .Landing-logo-image-partner {
    height: 41.3px;
    margin: 24.1px 0 0 0;
    width: 118.71px;
  }

  .Landing-partner-logo {
    height: 38.79px;
    margin: 24.1px 0 0 0;
    width: 133.84px;
  }

  .Landing-header-partner {
    justify-content: space-around;
    margin: 0;
    padding: 0 16.16px 0 16.02px;
    width: 100%;
  }

  .Landing-header-partner-good-dog {
    justify-content: flex-start;
    padding: 20px 0 20px 20px;
  }

  .Landing-header-partner-good-dog img:first-child {
    border-right: 1px solid #000;
    padding: 8px 5px 4px 0;
    margin: 0;
    align-self: center;
  }

  .Landing-header-partner-good-dog img:last-child {
    padding-left: 9px;
    margin: -8px 0 0 0;
    align-self: center;
  }

  .Landing-header-top-disclaimer>div>span {
    margin: 7.18px 16px;
  }
}
