.Purchase-retry-cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -90px 8px 20px;
  top: -100px;
}

.Purchase-retry-container {
  align-self: baseline;
  flex-direction: column;
  height: fit-content;
  margin: 8px;
  max-width: 414px;
  padding: 32px 55px;
}

.Purchase-retry-container-right {
  flex-direction: column;
  margin: 8px;
  max-width: 281px;
  padding: 32px 55px;
}

.Purchase-retry-card {
  background-color: #fbf6f1;
  border-radius: 16px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
  display: flex;
}

.Purchase-retry-card ul {
  padding: 0;
}

.Purchase-retry-card ul li {
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
}

.Purchase-retry-card ul li img {
  height: 40px;
  padding-right: 10px;
}

.Purchase-retry-pet-name {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Regular';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
}

.Purchase-retry-today-total-div {
  color: #000;
  display: flex;
  font-family: 'IvyEpic-Regular';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  justify-content: space-between;
  line-height: 27px;
  padding-bottom: 16px;
}

.Purchase-retry-today-total {
  font-weight: 700;
}

.Purchase-retry-pet-price {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.Purchase-retry-card>h3 {
  background-color: #fbf6f1;
  color: var(--deep-blue-main);
  font-family: 'IvyPresto-Regular';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
  margin: 0;
  padding: 0;
}

.Purchase-retry-card h1 {
  color: var(--deep-blue-main);
  font-family: 'IvyPresto-Regular';
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 46px;
  margin: 0;
  padding: 0;
}

.Purchase-retry-summary-p {
  color: #3d484d;
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.Purchase-retry-button {
  align-self: center;
  background: var(--deep-blue-main);
  border-radius: 32px;
  color: #fff;
  cursor: pointer;
  font-family: 'Inter-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  margin-top: 18px;
  min-width: 257px;
  padding: 13px 23px;
}

.Purchase-retry-button-return {
  align-items: center;
  align-self: center;
  background-color: transparent;
  color: var(--deep-blue-shade-2);
  cursor: pointer;
  display: flex;
  font-family: 'Inter-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 18px;
  outline-color: #2fb3b4;
}

.Purchase-retry-button-return img {
  padding-right: 6px;
}

/* Tablet - Big */
@media (max-width: 1200px) and (min-width: 569px) {
  .Purchase-retry-cards-container {
    align-items: center;
    justify-content: center;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Purchase-retry-cards-container {
    margin: -16px;
  }

  .Purchase-retry-card {
    box-shadow: unset;
    border-radius: unset;
  }

  .Purchase-retry-container {
    margin: 0px;
    max-width: unset;
    padding: 32px;
    width: 100%;
  }

  .Purchase-retry-container-right {
    margin: 0;
    max-width: unset;
    background-color: unset;
    padding: 0;
    width: 100%;
  }

  .Purchase-retry-card>h3 {
    text-align: center;
  }

  .Purchase-retry-questions {
    display: flex;
    padding-top: 40px;
    justify-content: center;
  }

  .Purchase-retry-container-mobile {
    padding: 32px;
    background-color: #fbf6f1;
  }
}
