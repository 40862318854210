.Questions-sidebar-container {
  width: 262px;
}

.Questions-sidebar-help-desk-icon {
  height: 120px;
  width: 191px;
}

.Questions-sidebar-title {
  color: #22435e;
  font-family: 'DMSerifDisplay-Regular';
  font-size: 28px;
  font-weight: normal;
  margin: 10px 0;
}

.Questions-sidebar-title-purchase {
  color: #22435e;
  font-family: 'IvyPresto-Regular';
  font-size: 28px;
  font-weight: normal;
  letter-spacing: -0.4px;
  margin-bottom: 8px;
  margin-top: 21px;
}

.Questions-sidebar-questions-text {
  color: var(--deep-blue-shade-2) !important;
  font-family: 'IvyEpic-Regular';
  font-weight: 700;
  font-size: 14px !important;
  line-height: 1.43;
}

.Questions-sidebar-questions-text-subtitle {
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 0px;
  width: 200px;
}

.Questions-sidebar {
  display: block;
}

.Questions-purchase {
  background-color: transparent !important;
  display: block;
  margin: 0;
}

.Questions-purchase .Questions-sidebar-questions-text {
  color: #22435e;
  font-size: 16px;
}

.Questions-purchase .Questions-sidebar-questions-text>a {
  color: #22435e;
  font-family: 'Inter-Medium';
}

.Questions-purchase .Questions-sidebar-questions-text-subtitle {
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  font-weight: 400;
}

.Question-sidebar-text-mobile p:first-child {
  margin-top: 0;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Questions-sidebar {
    display: none;
  }

  .Questions-sidebar-title {
    margin: 0 0 34px;
    order: -1;
  }

  .Questions-sidebar-header {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
  }

  .Questions-purchase {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 40px 0 48px 0;
  }

  .Questions-purchase .Questions-sidebar-questions-text {
    text-align: left;
  }

  .Questions-purchase .Questions-sidebar-header {
    display: flex;
    flex-direction: column-reverse;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Questions-sidebar-title {
    order: -1;
    margin: 0 0 24px 0;
  }

  .Questions-sidebar-questions-text {
    margin: 12px 0;
    text-align: center !important;
  }

  .Question-sidebar-text-mobile {
    display: flex;
    flex-direction: column;
  }

  .Questions-purchase .Questions-sidebar-header {
    display: flex;
    flex-direction: column;
  }

  .Questions-purchase {
    align-items: center;
    flex-direction: column;
  }

  .Questions-purchase .Questions-sidebar-title {
    margin: 0 0 16px 0;
  }

  .Questions-sidebar-questions-text-subtitle {
    align-self: center;
  }

  .Questions-sidebar-title-purchase {
    text-align: center;
  }
}
