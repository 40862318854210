.Figo-input-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
}

.Figo-input-label {
  color: var(--pc-color-onyx-500);
  display: flex;
  flex-direction: column;
  font-family: 'IvyEpic-Bold';
  font-size: 14px;
  line-height: 21px;
  position: relative;
}

.Figo-input-input,
.Figo-input-large {
  background: var(--ivory-40);
  border-radius: 20px;
  border: 1px solid var(--midnight-base-500);
  color: var(--midnight-base-500);
  font-family: 'IvyEpic-Regular';
  font-size: 16px;
  line-height: 24px;
  padding: 8px 9px;
}

.Figo-input-input:focus,
.Figo-input-input:active {
  border: 2px solid var(--deep-blue-base-100);
}

.Figo-input-input::placeholder {
  color: var(--midnight-base-300);
}

.Figo-input-small {
  font-size: 14px;
  line-height: 21px;
  padding: 6px 9px;
}

.Figo-input-xl {
  border-radius: 40px;
  line-height: 22px;
  padding: 23px 16px;
}

.Figo-input-input[data-error=true] { 
  border-color: var(--danger-100) !important;
  outline-color: var(--danger-100);
}

.Figo-input-input:disabled {
  background-color: var(--cotton-200);
  border-color: var(--cotton-300);
  color: var(--midnight-300);
  outline-color: var(--cotton-300);
}

.Figo-input-right-display {
  bottom: 14px;
  position: absolute;
  right: 9px;
}

.Figo-input-small + .Figo-input-right-display {
  bottom: 12px;
}

.Figo-input-xl + .Figo-input-right-display {
  bottom: 30px;
}

.Figo-input-hint {
  color: var(--deep-blue-shade-2);
  font-family: 'IvyEpic-Regular';
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  padding-right: 10px;
  padding-top: 4px;
}
