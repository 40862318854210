.Input-with-error {
  background: transparent;
  border-color: #d9dee1;
  border-radius: 0.5px;
  border-style: none none solid none;
  border-width: 1px;
  color: #6d7579;
  font-family: 'Inter-Medium';
  font-size: 16px;
  outline-color: #2fb3b4;
  padding: 4px 0 12px 8px;
  width: 248px;
}

.Input-whit-error-label {
  color: var(--deep-blue-main);
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
  line-height: 1.538;
}

.Input-whit-error-label-fail {
  color: #cc0000;
}

.Input-with-error-error {
  border-color: #cc0000;
}

.Input-with-error:focus {
  border-image: linear-gradient(to right, #1cd4ce 0%, #1ecacb 13%, #2d86ba 100%) 1 stretch;
  border-radius: 1px;
  border-width: 2px;
  outline: 0;
}

.Input-with-error-label-error {
  color: #cc0000;
  font-family: 'Inter-Medium';
  font-size: 11px;
  margin-top: 2px;
  padding-left: 9px;
}

.Input-with-error::placeholder {
  color: #6d7579;
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
}

.Input-with-error-absolute-button {
  background-color: #fbf6f1;
  color: #2fb3b4;
  cursor: pointer;
  font-family: "Inter-Medium";
  font-size: 13px;
  position: absolute;
  right: 0;
  transition: all 0.5s ease;
}
