.Row {
  display: flex;
  flex-direction: row !important;
  width: auto;
}

.Column {
  display: flex;
  flex-direction: column !important;
  flex: 1;
  width: auto;
}

.Flex {
  display: flex;
}

.Inline {
  display: inline;
}

.Relative {
  position: relative;
}

.Absolute {
  position: absolute;
}

.Btn {
  cursor: pointer;
  outline-color: #2fb3b4;
}

.No-clicked {
  pointer-events: none;
}

.Hide {
  opacity: 0;
}

.Text-mint {
  color: #2fb3b4 !important;
}

.Text-navy,
.Text-title {
  color: #22435e;
}

.Text-primary {
  color: #3d484d;
}

.Text-black {
  color: #1e2226;
}

.Text-white {
  color: #fff !important;
}

.Text-center {
  text-align: center;
}

.IvyEpic-Regular-14 {
  font-family: 'IvyEpic-Regular';
  font-size: 14px;
}

.IvyEpic-bold-14 {
  font-family: 'IvyEpic-Bold';
  font-size: 14px;
}

.Inter-semi-bold-13 {
  font-family: 'Inter-SemiBold';
  font-size: 13px;
}

.Inter-semi-bold-14 {
  font-family: 'Inter-SemiBold';
  font-size: 14px;
}

.Inter-semi-bold-16 {
  font-family: 'Inter-SemiBold';
  font-size: 16px;
}

.Inter-semi-bold-17 {
  font-family: 'Inter-SemiBold';
  font-size: 17px;
}

.Inter-semi-bold-18 {
  font-family: 'Inter-SemiBold';
  font-size: 18px;
}

.Inter-semi-bold-19 {
  font-family: 'Inter-SemiBold';
  font-size: 19px;
}

.Inter-semi-bold-20 {
  font-family: 'Inter-SemiBold';
  font-size: 20px;
}

.Inter-semi-bold-21 {
  font-family: 'Inter-SemiBold';
  font-size: 21px;
}

.Inter-semi-bold-22 {
  font-family: 'Inter-SemiBold';
  font-size: 22px;
}

.Inter-regular-13 {
  font-family: 'Inter-Regular';
  font-size: 13px;
}

.Inter-regular-14 {
  font-family: 'Inter-Regular';
  font-size: 14px;
}

.Inter-regular-15 {
  font-family: 'Inter-Regular';
  font-size: 15px;
}

.Inter-regular-16 {
  font-family: 'Inter-Regular';
  font-size: 16px;
}

.Inter-medium-13 {
  font-family: 'Inter-Medium';
  font-size: 13px;
}

.Inter-medium-14 {
  font-family: 'Inter-Medium';
  font-size: 14px;
}

.Inter-medium-15 {
  font-family: 'Inter-Medium';
  font-size: 15px;
}

.Inter-medium-16 {
  font-family: 'Inter-Medium';
  font-size: 16px;
}

.Margin-bottom-24 {
  margin-bottom: 24px;
}
