.Card-plan-header-title {
  color: var(--deep-blue-main);
  font-family: 'IvyPresto-Regular';
  font-size: 36px;
  font-weight: normal;
  letter-spacing: -0.4px;
  line-height: 46px;
  margin: 40px 0 0 0;
  max-width: 640px;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
}

.Pet-parent-title-header {
  color: var(--deep-blue-main);
  font-family: 'IvyPresto-Regular';
  font-size: 36px;
  margin: 40px 0 0 64px;
  text-align: center;
}

.Pet-parent-title-header-partner {
  margin: 42px 0 30px 0;
}

.Pet-parent-title-header-partner-less-margin {
  margin: 32px 0 0 0;
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .Pet-parent-title-header-partner {
    display: block;
    margin: 40px 0 0 0;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Card-plan-header-title {
    font-size: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }

  .Pet-parent-title-header-partner-less-margin {
    margin: 24px 0 0;
  }
}
