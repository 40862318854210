.Terms-and-condition-checkbox-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 36px;
  justify-content: flex-start;
  padding-bottom: 10px;
  position: relative;
  width: 450px;
}

.Terms-and-condition-checkbox-label {
  align-items: center;
  color: #3d484d;
  cursor: pointer;
  display: flex;
  font-family: 'Inter-Medium';
  font-size: 13px;
  padding-bottom: 0px !important;
  pointer-events: none;
}

.Terms-and-condition-checkbox-input {
  top: 0px !important;
}

.Terms-and-condition-checkbox-content {
  align-self: center;
  display: flex;
  height: auto;
  width: 100%;
}

.Terms-and-condition-error-label {
  bottom: 3px;
  color: #c00000;
  font-family: 'Inter-Medium';
  font-size: 11px;
  padding-left: 33px;
  pointer-events: none;
  position: relative;
}

.Terms-and-condition-child-modal-clickable {
  background-color: transparent;
  color: var(--deep-blue-main);
  cursor: pointer;
  font-family: 'IvyEpic-Regular';
  font-size: 12px;
  padding: 0;
  pointer-events: all;
  text-decoration: underline;
}

.Terms-and-condition-checkbox {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 40px 48px 20px 48px;
  width: 90%;
}

.Terms-and-condition-checkbox input {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #888d95;
  border-radius: 100%;
  border: 2px solid #888d95;
  cursor: pointer;
  height: 20px;
  margin: 0 16px 0 0;
  min-width: 20px;
  position: relative;
  top: 4px;
  width: 20px;
}

.Terms-and-condition-error-label {
  bottom: 15px;
  padding: 0 !important;
  position: absolute;
  bottom: 4px;
  left: 0;
}

/* Mobile */
@media (max-width: 568px) {
  .Terms-and-condition-checkbox {
    margin: 40px 0 20px 50px;
  }

  .Terms-and-condition-checkbox input {
    margin: 0 8px 0 0;
  }

  .Terms-and-condition-checkbox-container {
    width: 100%;
  }
}