.Message {
  align-items: center;
  border-radius: 8px;
  color: var(--midnight-base-500);
  display: flex;
  flex-direction: row;
  font-family: "IvyEpic-Regular";
  font-size: 14px;
  gap: 8px;
  line-height: 16px;
  margin: 40px 0 24px;
  padding: 16px;
  max-width: 510px;
  width: 95%;
}

.Message:empty {
  background-color: transparent;
  border-color: transparent;
  height: 0;
  margin: 0;
  padding: 0;
}

.Message>img {
  vertical-align: middle;
  width: 20px;
}

.Message-error {
  background: var(--hydrant-200);
  border: 1px solid var(--hydrant-400);
}

.Message-info {
  background: var(--blue-100);
  border: 1px solid var(--blue-400);
}

/* Mobile */
@media (max-width: 568px) {
  .Message {
    padding: 8px;
    width: 94%;
  }
}
