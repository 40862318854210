.Footer-links-container {
  display: flow-root;
  margin: 20px 0;
  text-align: center;
}

.Footer-link {
  background-color: transparent;
  color: var(--ivory-main) !important;
  cursor: pointer;
  display: inline-block;
  font-family: "IvyEpic-Regular";
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  text-align: justify;
  text-decoration: none;
}

.Footer-link-separator {
  color: var(--ivory-main);
  font-family: 'Inter-Thin';
  font-size: 13px;
  margin: 0;
}

/* Tablet */
@media (min-width: 569px) and (max-width: 1200px) {
  .Footer-links-container {
    padding: 0 2%;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .Footer-links-container {
    display: flow-root;
    padding: 0 2%;
    text-align: center;
  }
}